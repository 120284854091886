import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import {baseUrl } from "../../../comman/config";
import { useNavigate, Link } from 'react-router-dom';

export default function Form({detail}) {
   
    const [formData, setFormData] = useState({
        name : '',
        bowl_color : '',
        bowl_diameter_bottom : '',
        bowl_diameter_marked : 0,
        bowl_diameter_top: '',
        bowl_marked_height: 0,
        bowl_total_height: '',
        bowl_marked_volume: '',
        bowl_top_volume: '',
        bowl_weight_empty:""
    });
    const [validationErrors, setValidationErrors] = useState({
        name: false,
        bowl_diameter_bottom: false,
        bowl_diameter_top: false,
        bowl_total_height: false,
        bowl_weight_empty: false,
        
    });
    const [popupOpen , setPopupOpen] = useState(false);
    const formRef = useRef(null);
    
    
    const navigate = useNavigate();
    
    useEffect(() => {
        if(detail){
            setFormData({
                name: detail[0].name || '',
                bowl_color: detail[0].bowl_color || '',
                bowl_diameter_bottom: detail[0].bowl_diameter_bottom || '',
                bowl_diameter_marked: detail[0].bowl_diameter_marked || 0,
                bowl_diameter_top: detail[0].bowl_diameter_top || '',
                bowl_marked_height: detail[0].bowl_marked_height || 0,
                bowl_total_height: detail[0].bowl_total_height || '',
                bowl_marked_volume: detail[0].bowl_marked_volume || '',
                bowl_top_volume: detail[0].bowl_top_volume || '',
                bowl_weight_empty: detail[0].bowl_weight_empty || ""
            });
        }
    }, [detail]);

    useEffect(() => {
        if(formData.bowl_marked_height && formData.bowl_total_height && formData.bowl_diameter_bottom && formData.bowl_diameter_marked && formData.bowl_diameter_top){
            const d1 = formData.bowl_diameter_bottom;
            const d2 = formData.bowl_diameter_marked;
            const d3 = formData.bowl_diameter_top;
            const h1 = formData.bowl_marked_height;
            const h2 = formData.bowl_total_height;
            const bowl_vol_marked = ((3.14 / 3  *  h1 * (((d1 / 2) * (d1 / 2)) + (d1 * d2) + ((d2 / 2) * (d2 / 2)))) / 1000);

            const bowl_vol_top = ((3.14 / 3  *  h2 * (((d1 / 2) * (d1 / 2)) + (d1 * d3) + ((d3 / 2) * (d3 / 2)))) / 1000);

            // setFormData(prevData => ({ ...prevData, bowl_marked_volume: bowl_vol_marked.toFixed(2), bowl_top_volume : bowl_vol_top.toFixed(2) }));
            if (
                formData.bowl_marked_volume !== bowl_vol_marked.toFixed(2) ||
                formData.bowl_top_volume !== bowl_vol_top.toFixed(2)
            ) {
                setFormData(prevData => ({
                    ...prevData,
                    bowl_marked_volume: bowl_vol_marked.toFixed(2),
                    bowl_top_volume: bowl_vol_top.toFixed(2)
                }));
            }
        }
    }, [formData]);



    const handleSubmit = async (e) => {
        e.preventDefault();
        const newValidationErrors = {
            name: formData.name === '',
            bowl_diameter_bottom : formData.bowl_diameter_bottom === '',
            bowl_diameter_top : formData.bowl_diameter_top === '',
            bowl_total_height : formData.bowl_total_height === '',
            bowl_weight_empty : formData.bowl_weight_empty === ''
            
        };
        setValidationErrors(newValidationErrors);
        const hasErrors = Object.values(newValidationErrors).some((error) => error);
        console.log(formData);
        if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }else {
            let url;
            try {
                setPopupOpen(true);
                if(detail){
                    url = `bowl_master/edit/${detail[0].id}`;
                }else{
                    url = "bowl_master/add";
                }
                await axios.post(`${baseUrl}/${url}`,{
                    formData: formData,
                });
                setPopupOpen(false);
                navigate("/bowl_master/list");
            } catch (error) {

                setPopupOpen(false);
                console.log(error);
                
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
    
            }));
      
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

  return (
 
 <form ref={formRef}>
                <div className="space-y-12">
                    <div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Bowl Name</label>
                                <div className="mt-2">
                                    <input
                                      type="text"
                                      name="name"
                                      id="name"
                                      placeholder='Bowl Name'
                                      value={formData.name}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.name ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.name && <div className="text-red-500">Please enter a bowl name.</div>}
                            </div>
                            
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                        <div className="sm:col-span-2">
                                <label htmlFor="bowl_color" className="block text-sm font-medium leading-6 text-gray-900">Bowl Color</label>
                                <div className="mt-2">
                                    <input
                                      id="bowl_color"
                                      name="bowl_color"
                                      type="text"
                                      placeholder='Bowl color'
                                      value={formData.bowl_color}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.bowl_color ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {/* {validationErrors.bowl_color && <div className="text-red-500">Please enter a bowl color.</div>} */}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="bowl_diameter_bottom" className="block text-sm font-medium leading-6 text-gray-900">Bow Diameter Bottom - D1(cms.)</label>
                                <div className="mt-2">
                                    
                                    <input 
                                      id="bowl_diameter_bottom"
                                      name="bowl_diameter_bottom"
                                      type="number"
                                      placeholder='Bow Diameter Bottom - D1(cms.)'
                                      step="0.01"
                                      value={formData.bowl_diameter_bottom}
                                      onChange={handleInputChange}
                                      onWheel={(e) => e.target.blur()}
                                      className={`block w-full rounded-md border ${validationErrors.bowl_diameter_bottom ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                        
                                    />
                                    
                                </div>
                                {validationErrors.bowl_diameter_bottom && <div className="text-red-500">Please enter bowl diameter bottom.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="bowl_diameter_marked" className="block text-sm font-medium leading-6 text-gray-900">Bowl Diameter Marked - D2 (cms.)</label>
                                <div className="mt-2">
                                    
                                    <input 
                                      id="bowl_diameter_marked"
                                      name="bowl_diameter_marked"
                                      type="number"
                                      step="0.01"
                                      placeholder='Bowl Diameter Marked - D2 (cms.)'
                                      value={formData.bowl_diameter_marked}
                                      onChange={handleInputChange}
                                      onWheel={(e) => e.target.blur()}
                                      className={`block w-full rounded-md border ${validationErrors.bowl_diameter_marked ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                        
                                    />
                                    
                                </div>
                                {validationErrors.bowl_diameter_marked && <div className="text-red-500">Please enter bowl diameter marked ht.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="bowl_diameter_top" className="block text-sm font-medium leading-6 text-gray-900">Bowl Diameter Top - D3 (cms.)</label>
                                <div className="mt-2">
                                    
                                    <input 
                                      id="bowl_diameter_top"
                                      name="bowl_diameter_top"
                                      type="number"
                                      step="0.01"
                                      placeholder='Bowl Diameter Top - D3 (cms.)'
                                      value={formData.bowl_diameter_top}
                                      onChange={handleInputChange}
                                      onWheel={(e) => e.target.blur()}
                                      className={`block w-full rounded-md border ${validationErrors.bowl_diameter_top ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                        
                                    />
                                    
                                </div>
                                {validationErrors.bowl_diameter_top && <div className="text-red-500">Please enter bowl top dia.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="bowl_marked_height" className="block text-sm font-medium leading-6 text-gray-900">Bowl Height Marked - H1 (cms.)</label>
                                <div className="mt-2">
                                    
                                    <input 
                                      id="bowl_marked_height"
                                      name="bowl_marked_height"
                                      type="number"
                                      step="0.01"
                                      placeholder='Bowl Height Marked - H1 (cms.)'
                                      value={formData.bowl_marked_height}
                                      onChange={handleInputChange}
                                      onWheel={(e) => e.target.blur()}
                                      className={`block w-full rounded-md border ${validationErrors.bowl_marked_height ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                        
                                    />
                                    
                                </div>
                                {validationErrors.bowl_marked_height && <div className="text-red-500">Please enter bowl marked height.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="bowl_total_height" className="block text-sm font-medium leading-6 text-gray-900">Bowl Height Total - H2 (cms.)</label>
                                <div className="mt-2">
                                    
                                    <input 
                                      id="bowl_total_height"
                                      name="bowl_total_height"
                                      type="number"
                                      step="0.01"
                                      value={formData.bowl_total_height}
                                      onChange={handleInputChange}
                                      onWheel={(e) => e.target.blur()}
                                      placeholder='Bowl Height Total - H2 (cms.)'
                                      className={`block w-full rounded-md border ${validationErrors.bowl_total_height ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                        
                                    />
                                    
                                </div>
                                {validationErrors.bowl_total_height && <div className="text-red-500">Please enter bowl total height.</div>}
                            </div>
                        </div>
                        
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="bowl_weight_empty" className="block text-sm font-medium leading-6 text-gray-900">Bowl Weight Empty (kgs.)</label>
                                <div className="mt-2">
                                    
                                    <input 
                                      id="bowl_weight_empty"
                                      name="bowl_weight_empty"
                                      type="number"
                                      step="0.01"
                                      placeholder='Bowl Weight Empty (kgs.)'
                                      value={formData.bowl_weight_empty}
                                      onChange={handleInputChange}
                                      onWheel={(e) => e.target.blur()}
                                      className={`block w-full rounded-md border ${validationErrors.bowl_weight_empty ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                        
                                    />
                                    
                                </div>
                                {validationErrors.bowl_weight_empty && <div className="text-red-500">Please enter bowl weight empty.</div>}
                            </div>
                        </div>
                        {/* {detail && <> */}
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="bowl_marked_volume" className="block text-sm font-medium leading-6 text-gray-900">Bowl Vol. Marked - V1 (ltr.)</label>
                                <div className="mt-2">
                                    
                                    <input 
                                      id="bowl_marked_volume"
                                      name="bowl_marked_volume"
                                      type="number"
                                      step="0.01"
                                      placeholder='Bowl Vol. Marked - V1 (ltr.)'
                                      value={formData.bowl_marked_volume}
                                      onChange={handleInputChange}
                                      onWheel={(e) => e.target.blur()}
                                      disabled
                                      className={`block w-full rounded-md border ${validationErrors.bowl_marked_volume ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                        
                                    />
                                    
                                </div>
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="bowl_top_volume" className="block text-sm font-medium leading-6 text-gray-900">Bowl Vol. Top - V2 (.)</label>
                                <div className="mt-2">
                                    
                                    <input 
                                      id="bowl_top_volume"
                                      name="bowl_top_volume"
                                      type="number"
                                      step="0.01"
                                      placeholder='Bowl Vol. Top - V2 (.)'
                                      value={formData.bowl_top_volume}
                                      onChange={handleInputChange}
                                      onWheel={(e) => e.target.blur()}
                                      disabled
                                      className={`block w-full rounded-md border ${validationErrors.bowl_weight_empty ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                        
                                    />
                                    
                                </div>
                            </div>
                        </div>
                        {/* </>} */}
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <Link to="/bowl_master/list">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                    </Link>
                    <button
                    onClick={handleSubmit}
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                </div>
            </form>
            )
        }