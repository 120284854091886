import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../comman/config";
import Select from "react-select";
import { useNavigate, Link } from "react-router-dom";

export default function Form({ detail, handelIngredientList }) {
  const [formData, setFormData] = useState({
    menuitem_id: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    menuitem_id: false,
  });
  const [menuitemList, setMenuitemList] = useState([]);
  const [ingredientList, setIngredientList] = useState([]);
  const [menuitemCategory, setMenuitemCategory] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [rows, setRows] = useState([
    { id: 1, ingredients_id: "", qty: "", ingredients_unit: "" },
  ]);
  const [rowCount, setRowCount] = useState(1);
  const [popupOpen, setPopupOpen] = useState(false);
  const formRef = useRef(null);

  const navigate = useNavigate();
  useEffect(() => {
    getMenuitemList();
    getIngredientList();
    getMenuitemCategory();
    // getIngredientCategory()
    getUnitList();
  }, []);

  useEffect(() => {
    if (detail && ingredientList) {
      setFormData({
        menuitem_id: detail[0].id || "",
      });
      const newArray = detail[0].rows.map((item, index) => {
        const unit =
          ingredientList.find((v) => v.id == item.ingredient_id)?.unit ?? null;
        return {
          id: index + 1,
          ingredients_id: item.ingredient_id.toString(),
          qty: item.qty,
          ingredients_unit: unit,
        };
      });
      console.log(newArray);
      setRows(newArray);
      setRowCount(newArray.length);
    }
    // console.log(detail[0]);
  }, [detail, ingredientList]);

  const getMenuitemList = async () => {
    const response = await axios.get(`${baseUrl}/item/list`);
    const list = response.data.data.filter((item) => item.status == 1);
    setMenuitemList(list);
  };

  const getIngredientList = async () => {
    const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`);
    const list = response.data.data.filter((item) => item.status == 1);
    setIngredientList(list);
    if(detail){
      handelIngredientList(list);
    }
    
  };
  const getMenuitemCategory = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=item_category_master&condition=status=1`
    );
    setMenuitemCategory(response.data.data);
  };

  const getUnitList = async () => {
    const response = await axios.get(`${baseUrl}/comman/list?table=units`);
    setUnitList(response.data.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const gujaratiRegex = /^[\u0A80-\u0AFF\s]+$/;
    const gujaratiRegex = /^[\u0A80-\u0AFF\s()+\-]*$/;
    const newValidationErrors = {
      menuitem_id: formData.menuitem_id === "",
      rows: isAddRowDisabled(),
    };
    console.log(rows);
    setValidationErrors(newValidationErrors);
    const hasErrors = Object.values(newValidationErrors).some((error) => error);
    // console.log(formData);
    if (hasErrors) {
      // Find the first invalid input element
      const firstInvalidInput = formRef.current.querySelector(".invalid");
      console.log(firstInvalidInput);
      if (firstInvalidInput) {
        // Scroll to the first invalid input element
        firstInvalidInput.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    } else {
      let url;

      try {
        setPopupOpen(true);
        if (detail) {
          url = `receipe/edit/${detail[0].id}`;
        } else {
          url = "receipe/add";
        }
        const response = await axios.post(`${baseUrl}/${url}`, {
          formData: formData,
          rows: rows,
        });
        setPopupOpen(false);
        if (response.data.status == true) {
          navigate("/receipe/list");
        } else {
          setValidationErrors({ error: response.data.msg });
        }
        //
      } catch (error) {
        setPopupOpen(false);
        console.log(error);
      }
    }
  };

  const handleMenuitemChange = (value) => {
    setFormData({ menuitem_id: value });
  };

  const handleAddRow = (e) => {
    e.preventDefault();
    console.log(rowCount);
    setRowCount(rowCount + 1);
    setRows([
      ...rows,
      { id: rowCount + 1, ingredients_id: "", qty: "", ingredients_unit: "" },
    ]);
  };

  const handleRemoveRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleIngredientsChange = (id, value) => {
    const unit = ingredientList.find((item) => item.id == value).unit;
    const selectedIngredient = rows.find((item) => item.ingredients_id == value);
    console.log(selectedIngredient);
    if (selectedIngredient) {
      // Display an alert if the value does not exist
      alert("Selected ingredient allready exist!");
      return;
  }
    setRows(
      rows.map((row) =>
        row.id === id
          ? { ...row, ingredients_id: value.toString(), ingredients_unit: unit }
          : row
      )
    );
  };

  const handleQtyChange = (id, value) => {
    setRows(rows.map((row) => (row.id === id ? { ...row, qty: value } : row)));
  };

  const handleIngredientsUnitChange = (id, value) => {
    setRows(
      rows.map((row) =>
        row.id === id ? { ...row, ingredients_unit: value } : row
      )
    );
  };

  const isRowValid = (row) => {
    // console.log(row);
    if (row.ingredients_id.trim() !== "" && row.qty.trim() !== "") {
      // Check if qty is a valid integer
      const qtyRegex = /^[0-9]+(?:\.[0-9]+)?$/;
      return qtyRegex.test(row.qty.trim());
    }
    return false;
  };
  const isAddRowDisabled = () => {
    return rows.some((row) => !isRowValid(row));
  };

  return (
    <form ref={formRef}>
      <div className="space-y-12">
        <div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-6 gap-x-5 gap-y-8">
            <div className="md:col-span-2 ms-4">
              <label
                htmlFor="menuitem_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Item Name
              </label>
              <div className="mt-2">
                <Select
                  classNamePrefix="select"
                  value={
                    menuitemList
                      .filter((item) => item.id === formData.menuitem_id)
                      .map((item) => ({
                        value: item.id,
                        label: `${item.item_name} (${item.eng_name})`,
                        eng_name: item.eng_name,
                      }))[0]
                  }
                  name="menuitem_id"
                  onChange={(selectedOption) => {
                    handleMenuitemChange(selectedOption.value);
                  }}
                  options={menuitemList.map((item) => ({
                    value: item.id,
                    label: `${item.item_name} (${item.eng_name})`,
                    eng_name: item.eng_name,
                    category: item.category,
                  }))}
                  isDisabled={detail && detail.length > 0}
                  filterOption={(option, inputValue) => {
                    return (
                      option.data.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase()) ||
                      option.data.eng_name
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    );
                  }}
                />
              </div>
              {validationErrors.menuitem_id && (
                <div className="text-red-500">Please select a menuitem.</div>
              )}
            </div>
            {/* <div className="col-span-1 md:col-span-2 lg:col-span-2">
        <label htmlFor="menuitem_eng" className="block text-sm font-medium leading-6 text-gray-900">Menuitem Name(Eng)</label>
        <div className="mt-2">
            <input
                id="menuitem_eng"
                name="menuitem_eng"
                type="text"
                value={formData.menuitem_eng}
                disabled
                className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
        </div>
    </div> */}
          </div>

          <div className="mt-5">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr className="text-center">
                  <th className="px-4 py-2">Ingredients</th>
                  <th className="px-4 py-2">Qty / 100 kg</th>
                  <th className="px-4 py-2">Unit</th>
                  <th className="px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {rows.map((row) => (
                  <tr key={row.id}>
                    <td className="px-4 py-2 text-center">
                      <Select
                        classNamePrefix="select"
                        className="w-80 "
                        value={
                          ingredientList
                            .filter((item) => item.id == row.ingredients_id)
                            .map((item) => ({
                              value: item.id,
                              label: `${item.ingredient} (${item.eng_name})`,
                              eng_name: item.eng_name,
                            }))[0]
                        }
                        onChange={(selectedOption) => {
                          handleIngredientsChange(row.id, selectedOption.value);
                        }}
                        options={ingredientList.map((item) => ({
                          value: item.id,
                          label: `${item.ingredient} (${item.eng_name})`,
                          eng_name: item.eng_name,
                        }))}
                        filterOption={(option, inputValue) => {
                          return (
                            option.data.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase()) ||
                            option.data.eng_name
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          );
                        }}
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <input
                        type="number"
                        value={row.qty}
                        onChange={(e) =>
                          handleQtyChange(row.id, e.target.value)
                        }
                        onWheel={(e) => e.target.blur()}
                        className="w-20  rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <input
                        type="text"
                        value={row.ingredients_unit?.name}
                        disabled
                        className="w-20 rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      
                      {row.id !== 1 && (
                        <button
                          onClick={() => handleRemoveRow(row.id)}
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        >
                          Remove
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {validationErrors.rows && (
              <div className="text-red-500">
                Please at least one ingredient and their quantity.
              </div>
            )}
          </div>
        </div>
        {validationErrors.error && (
          <div className="text-red-500">
            Please check receipe allready exist for this menu item.
          </div>
        )}
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
      
                       
                      
        <Link to="/receipe/list">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
        </Link>
        <button
                          onClick={handleAddRow}
                          disabled={isAddRowDisabled()}
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 text-sm rounded"
                        >
                          Add Row
                        </button>
        <button
          onClick={handleSubmit}
          type="submit"
          className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}
