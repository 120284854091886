import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import {baseUrl } from "../../comman/config";
import Select from 'react-select';
import { useNavigate, Link } from 'react-router-dom';

export default function Form({detail}) {
   
    const today = new Date().toISOString().split('T')[0];
    const [formData, setFormData] = useState({
        date : today,
        session : '',
        item : '',
        dish_count : 0,
        remarks: '',
       
    });
    const [fileChanged, setFileChanged] = useState(false); 
    const [validationErrors, setValidationErrors] = useState({
        date: false,
        session: false,
        item: false,
        dish_count: false
    });
    const [popupOpen , setPopupOpen] = useState(false);
    const [sessionList, setSessionList] = useState([]);
    const [mealItemList, setMealItemList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const formRef = useRef(null);
    
    const navigate = useNavigate();

    useEffect(() => {
        getSessionList();
        getItemList();
    }, []);

    useEffect(() => {
        if(formData.date && formData.session.value){
            getMealItemList(formData.date, formData.session.value);
        }
        
    }, [formData]);


    useEffect(() => {
        if(detail ){
            const session = detail[0].session;
            
            const sessionItem =  sessionList.find((item) => item.id == session);
            const item = itemList.find((item) => item.id == detail[0].item);
            const sessionObject = sessionItem ? {value : sessionItem.id , label : sessionItem.name} : {value : '', label : ''};
            const itemObject = item ? {value : item.id , label : `${item.item_name} (${item.eng_name})`} : {value : '', label : ''};
            setFormData({
                date: detail[0].date || today,
                session: sessionObject || '',
                item: itemObject || '',
                dish_count: detail[0].dish_count || 0,
                remarks: detail[0].remarks || '',
                
            });
        }
    }, [detail, sessionList, itemList]);


    const getSessionList =  async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=session_master&condition=status=1`);
        setSessionList(response.data.data);
    }

    const getItemList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=item_master&condition=status=1`);
        setItemList(response.data.data);
    }

    const getMealItemList = async (date, sessionValue) => {
        const condition = ` m.session='${sessionValue}' AND m.date='${date}'`;
        const response = await axios.get(`${baseUrl}/meal_master/item_list?condition=${condition}`);

        const shortageCondition = ` session = '${sessionValue}' AND date = '${date}'`;
        const shortageResponse = await axios.get(`${baseUrl}/comman/list?table=shortage_screen&condition=status=1 AND ${shortageCondition}`);

        
        const menuitemids = new Set(shortageResponse.data.data.map(i => i.item));
        const mealItemList = response.data.data.filter((item) => !menuitemids.has(item.menu_item_id));

        setMealItemList(mealItemList);
        
    }
 
    const handleSubmit = async (e) => {
        e.preventDefault();

        
        const newValidationErrors = {
            date: formData.date === '',
            session: formData.session === '',
            item: formData.item === '',
            dish_count: formData.dish_count === 0
        };
        setValidationErrors(newValidationErrors);
        const hasErrors = Object.values(newValidationErrors).some((error) => error);
        
        if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }else {
            let url;
            try {
                console.log(formData);
                setPopupOpen(true);
                if(detail){
                    url = `shortage_screen/edit/${detail[0].id}`;
                }else{
                    url = "shortage_screen/add";
                }

                

                await axios.post(`${baseUrl}/${url}`, {
                    formData: formData,
                });
                setPopupOpen(false);
                navigate("/shortage_screen/list");
            } catch (error) {

                setPopupOpen(false);
                console.log(error);
                
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
       
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const handleSelectChange = (data, option) => {
        
        if(option.name == "session"){
            setFormData((prevData) => ({
                ...prevData,
                [option.name] : data,
                item:''
               
            }));
        }else{
            setFormData((prevData) => ({
                ...prevData,
                [option.name] : data,
               
            }));
        }
        
        
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [option.name]: false
        }));
    }


  return (
 
<form ref={formRef} className="space-y-12 w-full lg:w-64">
  <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-8">
    <div className="sm:col-span-2">
      <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900">Date</label>
      <input
        type="date"
        name="date"
        id="date"
        value={formData.date}
        onChange={handleInputChange}
        className={`block w-full rounded-md border ${validationErrors.date ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
      />
      {validationErrors.date && <div className="text-red-500">Please enter a date.</div>}
    </div>
    <div className="sm:col-span-2">
      <label htmlFor="session" className="block text-sm font-medium leading-6 text-gray-900">Session</label>
      <Select
        classNamePrefix="select"
        value={formData.session}
        name="session"
        onChange={(selectedOption, name) => {
          handleSelectChange(selectedOption, name);
        }}
        options={sessionList.map(item => ({
          value: item.id,
          label: item.name
        }))}
        filterOption={(option, inputValue) => {
          return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
        }}
      />
      {validationErrors.session && <div className="text-red-500">Please select a session.</div>}
    </div>
    <div className="sm:col-span-2">
      <label htmlFor="type" className="block text-sm font-medium leading-6 text-gray-900">Item Name</label>
      <Select
        classNamePrefix="select"
        value={formData.item}
        name="item"
        onChange={(selectedOption, name) => {
          handleSelectChange(selectedOption, name);
        }}
        options={mealItemList.map(item => ({
          value: item.menu_item_id,
          label: `${item.item_name} (${item.eng_name})`
        }))}
        filterOption={(option, inputValue) => {
          return option.data.label.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.data.eng_name.toLowerCase().includes(inputValue.toLowerCase());
        }}
      />
      {validationErrors.item && <div className="text-red-500">Please select an item.</div>}
    </div>
    <div className="sm:col-span-2">
      <label htmlFor="dish_count" className="block text-sm font-medium leading-6 text-gray-900">Dish Count</label>
      <input
        id="dish_count"
        name="dish_count"
        type="number"
        step="0.01"
        placeholder='Dish Count'
        value={formData.dish_count}
        onChange={handleInputChange}
        onWheel={(e) => e.target.blur()}
        className={`block w-full rounded-md border ${validationErrors.dish_count ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
      />
      {validationErrors.dish_count && <div className="text-red-500">Please enter the dish count.</div>}
    </div>
    <div className="sm:col-span-2">
      <label htmlFor="remarks" className="block text-sm font-medium leading-6 text-gray-900">Remarks</label>
      <input
        id="remarks"
        name="remarks"
        type="text"
        placeholder='Remarks'
        value={formData.remarks}
        onChange={handleInputChange}
        className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
      />
    </div>
  </div>
  <div className="mt-6 flex items-center gap-x-6">
    <Link to="/shortage_screen/list">
      <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
    </Link>
    <button
      onClick={handleSubmit}
      type="submit"
      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      Save
    </button>
  </div>
</form>



            )
        }