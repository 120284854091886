
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import {baseUrl, limit} from "../../../comman/config";
import { PencilIcon, XCircleIcon } from '@heroicons/react/outline';
import { useNavigate, Link } from 'react-router-dom';
// import Pagination from "react-js-pagination";

function List() {

    const [consumptionMasterList, setConsumptionMasterList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        getMenuitems();
        
    }, []);

    useEffect(() => {
        let filterelist;
        if(searchQuery){
            filterelist = consumptionMasterList.filter(item => {
                return item.eng_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                       item.item_name.toLowerCase().includes(searchQuery.toLowerCase());
            });
        } else{
            filterelist = consumptionMasterList;
        }  
        setFilteredList(filterelist);
    }, [searchQuery]);
    const getMenuitems = async () => {
        // console.log(page);
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/consumption_master/list?page=${page}&limit=${limit}`, {
           
        });
        setPopupOpen(false);
        setConsumptionMasterList(response.data.data);
        setFilteredList(response.data.data);
        setTotalItem(response.data.totalItem);
    }

    const handelFilterChange= (e) => {
        const { value } = e.target;
        setSearchQuery(value);
    };



    const editMenuitem = async (id) => {
        navigate(`/consumption_master/edit/${btoa(id)}`, { state: consumptionMasterList.filter(item => item.id == id) });
    }

    const handleExport = () => {
        const header = ["Menuitem name", "Method", "RM to FG", "FG / Person", "RM to Bowl", "Bowl Qty", "Rm / 100-p", "Ras / 100-p", "Avg / Bowl", "Avg Density"];
        
        const csv = [header].concat(
            filteredList.map((item, index) => {
                let resultArray = [];
                            resultArray.push(`${item.item_name} (${item.eng_name})`);
                            resultArray.push(item.method);
                            resultArray.push(item.rm_to_fg_ratio);
                            resultArray.push(item.fg_consumption_per_person);
                            resultArray.push(item.rm_to_bowl_ratio);
                            resultArray.push(item.one_bowl_qty);
                            resultArray.push(item.raw_mat_per_100_person);
                            resultArray.push(item.ras_raw_mat_per_100_person);
                            resultArray.push(item.avg_consumption_per_bowl);
                            resultArray.push(item.avg_density);
                return resultArray.join(',');
            })
        ).join('\n');
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', "conumption_master");
        a.click();
    }
   


    return (
        <div className="p-4">
            <div className="flex justify-between items-center py-4 px-6">
                <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">Consumption Master List</h1>
                <div>
                    <Link to="/consumption_master/add">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Add Consumption Master</button>
                    </Link>
                    <button
                    onClick={handleExport}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                    Export
                </button>
                </div>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="pb-4 flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between">
                    <label htmlFor="table-search" className="sr-only">Search</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
                            <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                        </div>
                        <input type="text" value={searchQuery} onChange={handelFilterChange} id="table-search" className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items" />
                    </div>
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Menuitem name</th>
                            <th scope="col" className="px-6 py-3">Method</th>
                            <th scope="col" className="px-6 py-3">RM to FG</th>
                            <th scope="col" className="px-6 py-3">FG / Person</th>
                            <th scope="col" className="px-6 py-3">RM to Bowl</th>
                            <th scope="col" className="px-6 py-3">Bowl Qty</th>
                            <th scope="col" className="px-6 py-3">Rm / 100-p</th>
                            <th scope="col" className="px-6 py-3">Ras / 100-p</th>
                            <th scope="col" className="px-6 py-3">Avg / Bowl</th>
                            <th scope="col" className="px-6 py-3">Avg Density</th>
                            <th scope="col" className="px-6 py-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {filteredList.map((item) => (
                        <tr key={item.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.item_name} ({item.eng_name})</th>
                            <td className={`px-6 py-4`}>{item.method}</td>
                            <td className={`px-6 py-4`}>{item.rm_to_fg_ratio}</td>
                            <td className={`px-6 py-4`}>{item.fg_consumption_per_person}</td>
                            <td className={`px-6 py-4`}>{item.rm_to_bowl_ratio}</td>
                            <td className={`px-6 py-4`}>{item.one_bowl_qty}</td>
                            <td className={`px-6 py-4`}>{item.raw_mat_per_100_person}</td>
                            <td className={`px-6 py-4`}>{item.ras_raw_mat_per_100_person}</td>
                            <td className={`px-6 py-4`}>{item.avg_consumption_per_bowl}</td>
                            <td className={`px-6 py-4`}>{item.avg_density}</td>
                            <td className="px-6 py-4 flex">
                                    <a 
                                    onClick={() => editMenuitem(item.id)}
                                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-2">
                                        <PencilIcon className="w-4 h-4 inline-block" /> {/* Edit icon */}
                                    </a>
                                    
                                </td>
                        </tr>))}
           
                    </tbody>
                </table>
            </div>
        </div>
  );
}

export default List;