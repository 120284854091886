import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import {
  baseUrl,
  noOfGullaList,
  rotliTypeList,
  batchSize,
} from "../comman/config";
import Select from "react-select";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { DateSessionFilter } from "../components/Filter/DateSessionFilter";
import toast, { Toaster } from "react-hot-toast";

function ExcessScreen() {
  const today = new Date().toISOString().split("T")[0];
  const [mealList, setMealList] = useState([]);
  const [methodList, setMethodList] = useState([]);
  const [methodType, setMethodType] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const [batchIndex, setBatchIndex] = useState(0);
  const [bowlList, setBowlList] = useState([]);
  const [date, setDate] = useState(today);
  const [session, setSession] = useState("");
  const [formData, setFormData] = useState([]);
  const [vesselList, setVesselList] = useState([]);
  const [trollyList, setTrollyList] = useState([]);
  const [filleHegithChange, setFilleHegithChange] = useState(false);

  useEffect(() => {
    getBowlList();
    getVesselList();
    getTrollyList();
    getMethodList();
  }, []);
  useEffect(() => {
    // Create initial formData and validationErrors based on mealList length
    const initialFormData = Array.from({ length: batchSize }, (_, index) => ({
      batch: index + 1,
      trolly_name: "",
      vessel_name: "",
      gross_weight_fg: "",
      weight_of_empty_trolly: "",
      weight_of_empty_vessel: "",
      net_weight_fg_a: "",
      weight_of_1_filled_bowl: "",
      bowl_filled_weight_b: "",
      total_weight: "",
      empty_height: "",
      filled_height: "",
      vessel_volume: "",
      excess_bowl_nos: "",
      volume_of_1_filled_bowl: "",
      bowl_filled_volume: "",
      total_volume: "",
      gross_weight: "",
      weight_of_1_empty_bowl_icebox: "",
      no_of_bowl_icebox: "",
      total_weight_of_empty_bowl_icebox: "",
      net_weight_of_rotis: "",
      weight_of_1_roti: "",
      no_of_rotis_a: "",
      no_of_bowl_icebox_b: "",
      nos_in_1_bowl_icebox: "",
      no_of_rotis_b: "",
      no_of_rotis_c: "",
      total_no_of_roti: "",
    }));
    setFormData(initialFormData);
    // setIntialFormData(initialFormData);
  }, [batchSize]);

  useEffect(() => {
    if (mealList.length > 0 && activeIndex != null) {
      const item =
        mealList.find((item, index) => index === activeIndex) ?? null;
      const itemId = item.menu_item_id ?? null;
      const method = item?.measurements?.id ?? item.method;
      setMethodType(method);
      const fetchDetail = async () => {
        const condition = `date = '${date}' AND session = '${session.value}' AND menu_item_id = '${itemId}' AND method = '${method}'`;
        const response = await axios.get(
          `${baseUrl}/comman/list?table=bowl_screen&condition=${condition}`
        );

        const excessResponse = await axios.get(
          `${baseUrl}/comman/list?table=excess_screen&condition=${condition}`
        );

        const bowlDetail = response.data.data;
        const excessDetail = excessResponse.data.data;
        getDetailDispaly(bowlDetail, excessDetail, method);
      };
      fetchDetail();
    }
  }, [mealList, activeIndex]);

  const getDetailDispaly = async (bowlDetail, excessDetail, method) => {
    try {
      if (bowlDetail || excessDetail) {
        const initialFormData = Array.from(
          { length: batchSize },
          (_, index) => ({
            batch: index + 1,
            trolly_name: "",
            vessel_name: "",
            gross_weight_fg: "",
            weight_of_empty_trolly: "",
            weight_of_empty_vessel: "",
            net_weight_fg_a: "",
            weight_of_1_filled_bowl: "",
            bowl_filled_weight_b: "",
            total_weight: "",
            empty_height: "",
            filled_height: "",
            vessel_volume: "",
            excess_bowl_nos: "",
            volume_of_1_filled_bowl: "",
            bowl_filled_volume: "",
            total_volume: "",
            gross_weight: "",
            weight_of_1_empty_bowl_icebox: "",
            no_of_bowl_icebox: "",
            total_weight_of_empty_bowl_icebox: "",
            net_weight_of_rotis: "",
            weight_of_1_roti: "",
            no_of_rotis_a: "",
            no_of_bowl_icebox_b: "",
            nos_in_1_bowl_icebox: "",
            no_of_rotis_b: "",
            no_of_rotis_c: "",
            total_no_of_roti: "",
          })
        );
        const updatedFormData = initialFormData.map((initialItem) => {
          const bowlObject = bowlDetail.find(
            (object) => object.batch == initialItem.batch
          );
          const excessObject = excessDetail.find(
            (object) => object.batch == initialItem.batch
          );

          if (bowlObject && !excessObject) {
            if (method == 1 || method == 4) {
              initialItem.weight_of_1_filled_bowl =
                bowlObject.avg_weight_of_1_filled_bowl;
            }
            if (method == 2 || method == 5) {
              initialItem.volume_of_1_filled_bowl =
                bowlObject.avg_volume_of_1_filled_bowl;
            }
            if (method == 3 || method == 6) {
              initialItem.weight_of_1_roti = bowlObject.weight_of_1_roti ?? 0;
              initialItem.weight_of_1_empty_bowl_icebox =
                bowlObject.weight_of_1_empty_bowl_icebox ?? 0;

              const sum =
                bowlDetail.reduce(
                  (accumulator, currentValue) =>
                    accumulator +
                    parseFloat(currentValue.no_of_rotis_in_1_bowl_icebox),
                  0
                ) ?? 0;
              initialItem.nos_in_1_bowl_icebox = (
                sum / bowlDetail.length
              ).toFixed();
            }
            return { ...initialItem };
          } else if (excessObject) {
            if (method == 1 || method == 4) {
              const trollyItem = trollyList.find(
                (item) => item.id == excessObject.trolly_name
              );
              const trollyNameObject = trollyItem
                ? { value: trollyItem.id, label: trollyItem.name }
                : { value: "", label: "" };

              const vesselItem = vesselList.find(
                (item) => item.id == excessObject.vessel_name
              );
              const vesselNameObject = vesselItem
                ? { value: vesselItem.id, label: vesselItem.name }
                : { value: "", label: "" };

              initialItem.trolly_name = trollyNameObject;
              initialItem.vessel_name = vesselNameObject;
              initialItem.gross_weight_fg = excessObject.gross_weight_fg;
              initialItem.weight_of_empty_trolly =
                excessObject.weight_of_empty_trolly;
              initialItem.weight_of_empty_vessel =
                excessObject.weight_of_empty_vessel;
              initialItem.net_weight_fg_a = excessObject.net_weight_fg_a;
              initialItem.excess_bowl_nos = excessObject.excess_bowl_nos;
              initialItem.weight_of_1_filled_bowl =
                excessObject.weight_of_1_filled_bowl;
              initialItem.bowl_filled_weight_b =
                excessObject.bowl_filled_weight_b;
              initialItem.total_weight = excessObject.total_weight;
            }
            if (method == 2 || method == 5) {
              const vesselItem = vesselList.find(
                (item) => item.id == excessObject.vessel_name
              );
              const vesselNameObject = vesselItem
                ? { value: vesselItem.id, label: vesselItem.name }
                : { value: "", label: "" };

              initialItem.vessel_name = vesselNameObject;
              initialItem.empty_height = excessObject.empty_height;
              initialItem.filled_height = excessObject.filled_height;
              initialItem.vessel_volume = excessObject.vessel_volume;
              initialItem.excess_bowl_nos = excessObject.excess_bowl_nos;
              initialItem.volume_of_1_filled_bowl =
                excessObject.volume_of_1_filled_bowl;
              initialItem.bowl_filled_volume = excessObject.bowl_filled_volume;
              initialItem.total_volume = excessObject.total_volume;
            }
            if (method == 3 || method == 6) {
              initialItem.gross_weight = excessObject.gross_weight;
              initialItem.weight_of_1_empty_bowl_icebox =
                excessObject.weight_of_1_empty_bowl_icebox;
              initialItem.no_of_bowl_icebox = excessObject.no_of_bowl_icebox;
              initialItem.total_weight_of_empty_bowl_icebox =
                excessObject.total_weight_of_empty_bowl_icebox;
              initialItem.net_weight_of_rotis =
                excessObject.net_weight_of_rotis;
              initialItem.weight_of_1_roti = excessObject.weight_of_1_roti;
              initialItem.no_of_rotis_a = excessObject.no_of_rotis_a;
              initialItem.no_of_bowl_icebox_b =
                excessObject.no_of_bowl_icebox_b;
              initialItem.nos_in_1_bowl_icebox =
                excessObject.nos_in_1_bowl_icebox;
              initialItem.no_of_rotis_b = excessObject.no_of_rotis_b;
              initialItem.no_of_rotis_c = excessObject.no_of_rotis_c;
              initialItem.total_no_of_roti = excessObject.total_no_of_roti;
            }
            return { ...initialItem };
          } else {
            return { ...initialItem };
          }
        });
        setFormData(updatedFormData);
      } else {
        const initialFormData = Array.from(
          { length: batchSize },
          (_, index) => ({
            batch: index + 1,
            trolly_name: "",
            vessel_name: "",
            gross_weight_fg: "",
            weight_of_empty_trolly: "",
            weight_of_empty_vessel: "",
            net_weight_fg_a: "",
            weight_of_1_filled_bowl: "",
            bowl_filled_weight_b: "",
            total_weight: "",
            empty_height: "",
            filled_height: "",
            vessel_volume: "",
            excess_bowl_nos: "",
            volume_of_1_filled_bowl: "",
            bowl_filled_volume: "",
            total_volume: "",
            gross_weight: "",
            weight_of_1_empty_bowl_icebox: "",
            no_of_bowl_icebox: "",
            total_weight_of_empty_bowl_icebox: "",
            net_weight_of_rotis: "",
            weight_of_1_roti: "",
            no_of_rotis_a: "",
            no_of_bowl_icebox_b: "",
            nos_in_1_bowl_icebox: "",
            no_of_rotis_b: "",
            no_of_rotis_c: "",
            total_no_of_roti: "",
          })
        );
        setFormData(initialFormData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const memoizedFormData = formData[batchIndex];
    const memoizedMethodType = methodType;

    if (
      (memoizedMethodType == 1 || memoizedMethodType == 4) &&
      memoizedFormData
    ) {
      const trollyWeight = parseFloat(memoizedFormData.weight_of_empty_trolly);
      const vesselWeight = parseFloat(memoizedFormData.weight_of_empty_vessel);
      const grossFG = parseFloat(memoizedFormData.gross_weight_fg);
      const excessBowlNos = memoizedFormData.excess_bowl_nos;
      const weight_of_1_filled_bowl = memoizedFormData.weight_of_1_filled_bowl;

      const net_weight_fg_a = isNaN(grossFG - (vesselWeight + trollyWeight))
        ? 0
        : (grossFG - (vesselWeight + trollyWeight)).toFixed(2);

      const bowl_filled_weight_b = (
        excessBowlNos * weight_of_1_filled_bowl
      ).toFixed(2);
      const total_weight =
        parseFloat(net_weight_fg_a) + parseFloat(bowl_filled_weight_b) ?? 0;

      // Update formData if necessary
      if (
        memoizedFormData.net_weight_fg_a !== net_weight_fg_a ||
        memoizedFormData.bowl_filled_weight_b !== bowl_filled_weight_b ||
        memoizedFormData.total_weight !== total_weight.toFixed(2)
      ) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            net_weight_fg_a: net_weight_fg_a,
            bowl_filled_weight_b: bowl_filled_weight_b,
            total_weight: total_weight.toFixed(2),
          };
          return updatedFormData;
        });
      }
    } else if (
      (memoizedMethodType == 2 || memoizedMethodType == 5) &&
      memoizedFormData
    ) {
      const vesselItem = vesselList.find(
        (item) => item.id == memoizedFormData?.vessel_name?.value
      );
      const total_height = vesselItem?.total_height ?? 0;
      const empty_height = memoizedFormData.empty_height;
      const d1 = vesselItem?.top_dia ?? 0;

      const excess_bowl_nos = memoizedFormData.excess_bowl_nos;
      const volume_of_1_filled_bowl = memoizedFormData.volume_of_1_filled_bowl;

      const filled_height = filleHegithChange
        ? formData[batchIndex].filled_height
        : isNaN(total_height - empty_height)
        ? 0
        : (total_height - empty_height).toFixed(2) ?? 0;
      const volume = (3.14 * (d1 / 2) * (d1 / 2) * filled_height) / 1000;
      const vessel_volume = isNaN(volume) ? 0 : volume.toFixed(2);

      const bowl_filled_volume =
        (excess_bowl_nos * volume_of_1_filled_bowl).toFixed(2) ?? 0;

      const total_volume =
        (parseFloat(vessel_volume) + parseFloat(bowl_filled_volume)).toFixed(
          2
        ) ?? 0;

      // Update formData if necessary
      if (
        memoizedFormData.filled_height !== filled_height ||
        memoizedFormData.vessel_volume !== vessel_volume ||
        memoizedFormData.bowl_filled_volume !== bowl_filled_volume ||
        memoizedFormData.total_volume !== total_volume
      ) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            filled_height: filled_height,
            vessel_volume: vessel_volume,
            bowl_filled_volume: bowl_filled_volume,
            total_volume: total_volume,
          };
          return updatedFormData;
        });
      }
    } else if (
      (memoizedMethodType == 3 || memoizedMethodType == 6) &&
      memoizedFormData
    ) {
      const no_of_bowl_icebox = memoizedFormData.no_of_bowl_icebox;
      const weight_of_1_empty_bowl_icebox =
        memoizedFormData.weight_of_1_empty_bowl_icebox;
      const gross_weight = memoizedFormData.gross_weight;
      const weight_of_1_roti = memoizedFormData.weight_of_1_roti;
      const no_of_bowl_icebox_b = memoizedFormData.no_of_bowl_icebox_b;
      const nos_in_1_bowl_icebox = memoizedFormData.nos_in_1_bowl_icebox;
      const no_of_rotis_c = memoizedFormData.no_of_rotis_c;

      const total_weight_of_empty_bowl_icebox =
        (no_of_bowl_icebox * weight_of_1_empty_bowl_icebox).toFixed(2) ?? 0;
      const net_weight_of_rotis =
        (gross_weight - total_weight_of_empty_bowl_icebox).toFixed(2) ?? 0;
      const no_of_rotis_a =
        weight_of_1_roti > 0
          ? (net_weight_of_rotis / weight_of_1_roti).toFixed(2)
          : 0;

      const no_of_rotis_b =
        (no_of_bowl_icebox_b * nos_in_1_bowl_icebox).toFixed(2) ?? 0;

      const total_no_of_roti =
        (
          parseFloat(no_of_rotis_a) +
          parseFloat(no_of_rotis_b) +
          parseFloat(no_of_rotis_c)
        ).toFixed(2) ?? 0;

      // Update formData if necessary
      if (
        memoizedFormData.total_weight_of_empty_bowl_icebox !==
          total_weight_of_empty_bowl_icebox ||
        memoizedFormData.net_weight_of_rotis !== net_weight_of_rotis ||
        memoizedFormData.no_of_rotis_a !== no_of_rotis_a ||
        memoizedFormData.no_of_rotis_b !== no_of_rotis_b ||
        memoizedFormData.total_no_of_roti !== total_no_of_roti
      ) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            total_weight_of_empty_bowl_icebox:
              total_weight_of_empty_bowl_icebox,
            net_weight_of_rotis: net_weight_of_rotis,
            no_of_rotis_a: no_of_rotis_a,
            no_of_rotis_b: no_of_rotis_b,
            total_no_of_roti: total_no_of_roti,
          };
          return updatedFormData;
        });
      }
    }
  }, [formData, methodType, batchIndex]);
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const getBowlList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=bowl_master&condition=status=1`
    );
    setBowlList(response.data.data);
  };

  const getVesselList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=vessel_master&condition=status=1`
    );
    setVesselList(response.data.data);
  };

  const getTrollyList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=trolly_master&condition=status=1`
    );
    setTrollyList(response.data.data);
  };

  const getMethodList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=method_master&condition=status=1`
    );
    setMethodList(response.data.data);
  };

  const handelFilterChange = async (date, session) => {
    setDate(date);
    setSession(session);
    if (date && session) {
      const condition = ` m.session='${session.value}' AND m.date='${date}'`;
      const response = await axios.get(
        `${baseUrl}/meal_master/item_list?condition=${condition}`
      );
      const newData = response.data.data.reduce((acc, obj) => {
        const measurementsArray = obj?.measurements?.split(",") ?? [obj.method];
        measurementsArray.forEach((measurement) => {
          acc.push({
            ...obj,
            measurements: methodList.find((v) => v.id == measurement) ?? {},
          });
        });
        return acc;
      }, []);

      console.log(newData);
      setMealList(newData);
    }
  };
  const handleBlur = (index) => (e) => {
    setFilleHegithChange(false);
  };
  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;
    if (name == "filled_height") {
      setFilleHegithChange(true);
    }
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index] = { ...updatedFormData[index], [name]: value };
      return updatedFormData;
    });
    setBatchIndex(index);
  };

  const handleSelectChange = async (index, data, option) => {
    setBatchIndex(index);
    if (methodType == 1 || methodType == 4) {
      if (option.name == "trolly_name") {
        const trollyWeight =
          trollyList.find((item) => item.id == data.value).empty_weight ?? 0;
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[index] = {
            ...updatedFormData[index],
            [option.name]: data,
            weight_of_empty_trolly: trollyWeight,
          };
          return updatedFormData;
        });
      }
      if (option.name == "vessel_name") {
        const vesselWeight =
          vesselList.find((item) => item.id == data.value).empty_weight ?? 0;
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[index] = {
            ...updatedFormData[index],
            [option.name]: data,
            weight_of_empty_vessel: vesselWeight,
          };
          return updatedFormData;
        });
      }
    } else {
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          [option.name]: data,
        };
        return updatedFormData;
      });
    }
  };

  const handleSave = async (e, index) => {
    e.preventDefault();

    const menu_item_id =
      mealList.find((item, i) => i == activeIndex)?.menu_item_id ?? 0;
    const combineData = {
      formData: formData[index],
      date: date,
      session: session,
      methodType: methodType,
      menu_item_id: menu_item_id,
    };
    console.log(combineData);
    try {
      // setPopupOpen(true);

      await axios.post(`${baseUrl}/excess_screen/batch`, combineData);
      toast.success("Data successfully saved!");
    } catch (error) {
      // setPopupOpen(false);
      toast.error("Failed to submit the form.");
      console.log(error);
    }
  };

  return (
    <div className="">
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
          Excess
        </h1>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <DateSessionFilter handleFilterChange={handelFilterChange} />
        <div>
          {mealList.map((item, index) => (
            <div key={index} className="border-b mt-4">
              <div
                className="flex justify-between items-center p-4 cursor-pointer accordion-title bg-orange-400 border rounded"
                onClick={() => handleClick(index)}
              >
                <span>
                  {`${item.item_name} (${item.eng_name})`} (
                  {item.measurements.name})
                </span>
                {index === activeIndex ? (
                  <ChevronUpIcon className="w-6 h-6" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6" />
                )}
              </div>
              {index === activeIndex && (
                <div className="space-y-2 p-4 bg-white">
                  <div className="flex overflow-x-auto">
                    <div className="flex mt-10 flex-col whitespace-nowrap">
                      <div className="border-l-2 border-l-red-500 border-t-2 border-t-red-500 border-b-red-500">
                        <div className="font-bold mt-[17px] ms-4">Batch</div>
                      </div>

                      {(item?.measurements?.id == 1 ||
                        item?.measurements?.id == 4) && (
                        <>
                          <div className="border-l-2 border-t-2 border-l-red-500 border-t-red-500 border-b-2 border-b-red-500 p-[13px]">
                            <div className="font-bold mt-3">Trolly Name</div>
                            <div className="font-bold mt-7">Vessel Name</div>
                            <div className="font-bold mt-7">
                              Gross weight FG(Kgs.)
                            </div>
                            <div className="font-bold mt-6">
                              Weight of Empty trolley
                            </div>
                            <div className="font-bold mt-7">
                              Weight of Empty vessel
                            </div>
                            <div className="font-bold mt-7">
                              Net weight - FG -(Kgs.) - A
                            </div>
                          </div>

                          <div className="border-l-2 border-l-red-500 border-b-2 border-t-2  p-[13px]">
                            <div className="font-bold ">Excess Bowl nos.</div>
                            <div className="font-bold mt-7">
                              Weight of 1 Filled bowl (kgs.)
                            </div>
                            <div className="font-bold mt-7">
                              Bowl Filled Weight (Kgs.) - B
                            </div>
                          </div>
                          <div className="border-l-2 -mt-[3px] border-l-red-500  border-b-2 border-b-red-500 border-t-2 border-t-red-500 p-[13.5px]">
                            <div className="font-bold ">
                              Total Weight (Kgs.)
                            </div>
                          </div>
                        </>
                      )}
                      {(item?.measurements?.id == 2 ||
                        item?.measurements?.id == 5) && (
                        <>
                          <div className="border-l-2  border-l-red-500  border-t-2 border-t-red-500 p-[13px]">
                            <div className="font-bold mt-[9px]">
                              Vessel Name*
                            </div>
                            <div className="font-bold mt-5">
                              Empty height (cms)*
                            </div>
                            <div className="font-bold mt-7">
                              Filled height (Cms.)
                            </div>
                            <div className="font-bold mt-8">
                              Vessel Volume (Ltrs) - A
                            </div>
                          </div>
                          <div className="border-l-2 -mt-[1px] border-l-red-500   border-t-2 border-t-red-500 p-[13px]">
                            <div className="font-bold mt-[1px]">
                              Excess Bowl nos.
                            </div>
                            <div className="font-bold mt-7">
                              Volume of 1 Filled bowl (ltrs.)
                            </div>
                            <div className="font-bold mt-7">
                              Bowl. Filled Volume (ltrs.) - B
                            </div>
                          </div>
                          <div className="border-l-2  border-l-red-500 border-b-2 border-b-red-500 border-t-2 border-t-red-500 p-[13px]">
                            <div className="font-bold  mt-[1px]">
                              Total Volume (ltrs.)
                            </div>
                          </div>
                        </>
                      )}
                      {(item?.measurements?.id == 3 ||
                        item?.measurements?.id == 6) && (
                        <>
                          <div className="border-l-2 border-t-2 border-l-red-500 border-t-red-500 border-b-2 border-b-red-500 p-[16px]">
                            <div className="font-bold mt-[2px]">
                              Gross weight (Ice box+Rotli)
                            </div>
                            <div className="font-bold mt-6">
                              Weight of 1 empty Icebox / bowl
                            </div>
                            <div className="font-bold mt-6">
                              No. of Bowl/icebox
                            </div>
                            <div className="font-bold mt-8">
                              Total Weight of empty Icebox / bowl
                            </div>
                            <div className="font-bold mt-6">
                              Net weight of rotis
                            </div>
                            <div className="font-bold mt-7">
                              Weight of 1 roti
                            </div>
                            <div className="font-bold mt-7">No of rotis(A)</div>
                          </div>
                          <div className="border-l-2  border-l-red-500  border-b-2 border-b-red-500 p-[13px]">
                            <div className="font-bold mt-[2px]">
                              No of icebox / bowl
                            </div>
                            <div className="font-bold mt-7">
                              Nos in 1 icebox / bowl
                            </div>
                            <div className="font-bold mt-7">
                              No.of rotis (B)
                            </div>
                          </div>

                          <div className="border-l-2 border-l-red-500 border-t-red-500 border-b-2 border-b-red-500 p-[13px]">
                            <div className="font-bold mt-[1px]">
                              No.of rotis (C)
                            </div>
                          </div>
                          <div className="border-l-2 border-l-red-500 border-t-red-500 border-b-2 border-b-red-500 p-[13px]">
                            <div className="font-bold ">Total No. Of Rotis</div>
                          </div>
                        </>
                      )}
                    </div>
                    {Array.from({ length: batchSize }, (_, index) => (
                      <div
                        key={index}
                        className="  gap-5 mb-4 mt-10 flex-shrink-0 w-52"
                      >
                        <div
                          className={`border border-t-2 ${
                            index === 4 ? "border-r-2 border-r-red-500" : ""
                          }  border-t-red-500 p-2`}
                        >
                          {index + 1}
                        </div>

                        {(item?.measurements?.id == 1 ||
                          item?.measurements?.id == 4) && (
                          <>
                            <div className="">
                              <div
                                className={`border p-2  border-t-2 border-t-red-500 ${
                                  index === 4
                                    ? "border-r-2 border-r-red-500"
                                    : ""
                                }`}
                              >
                                <Select
                                  classNamePrefix="select"
                                  styles={{
                                    placeholder: (provided) => ({
                                      ...provided,
                                      fontSize: "0.80rem",
                                    }),
                                    control: (provided) => ({
                                      ...provided,
                                      backgroundColor: "#FFFAF0",
                                      fontSize: "16px",
                                    }),
                                  }}
                                  name="trolly_name"
                                  value={formData?.[index].trolly_name}
                                  onChange={(selectedOption, name) => {
                                    handleSelectChange(
                                      index,
                                      selectedOption,
                                      name
                                    );
                                  }}
                                  placeholder="Trolly Name"
                                  options={trollyList.map((item) => ({
                                    value: item.id,
                                    label: item.name,
                                  }))}
                                  filterOption={(option, inputValue) => {
                                    return option.data.label
                                      .toLowerCase()
                                      .includes(inputValue.toLowerCase());
                                  }}
                                />
                              </div>
                              <div
                                className={`border p-2  ${
                                  index === 4
                                    ? "border-r-2 border-r-red-500"
                                    : ""
                                }`}
                              >
                                <Select
                                  classNamePrefix="select"
                                  styles={{
                                    placeholder: (provided) => ({
                                      ...provided,
                                      fontSize: "0.80rem",
                                    }),
                                    control: (provided) => ({
                                      ...provided,
                                      backgroundColor: "#FFFAF0",
                                      fontSize: "16px",
                                    }),
                                  }}
                                  name="vessel_name"
                                  value={formData?.[index].vessel_name}
                                  onChange={(selectedOption, name) => {
                                    handleSelectChange(
                                      index,
                                      selectedOption,
                                      name
                                    );
                                  }}
                                  placeholder="Vessel Name"
                                  options={vesselList.map((item) => ({
                                    value: item.id,
                                    label: item.name,
                                  }))}
                                  filterOption={(option, inputValue) => {
                                    return option.data.label
                                      .toLowerCase()
                                      .includes(inputValue.toLowerCase());
                                  }}
                                />
                              </div>
                              <div
                                className={`border p-2  ${
                                  index === 4
                                    ? "border-r-2 border-r-red-500"
                                    : ""
                                }`}
                              >
                                <input
                                  type="number"
                                  step="0.01"
                                  id="gross_weight_fg"
                                  name="gross_weight_fg"
                                  placeholder="Gross weight FG(Kgs.)"
                                  value={formData?.[index]?.gross_weight_fg}
                                  onChange={handleInputChange(index)}
                                  onWheel={(e) => e.target.blur()}
                                  className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                                />
                              </div>
                              <div
                                className={`border p-2  ${
                                  index === 4
                                    ? "border-r-2 border-r-red-500"
                                    : ""
                                }`}
                              >
                                <input
                                  type="number"
                                  step="0.01"
                                  id="weight_of_empty_trolly"
                                  name="weight_of_empty_trolly"
                                  placeholder="Weight of Empty trolley"
                                  disabled
                                  value={
                                    formData?.[index]?.weight_of_empty_trolly
                                  }
                                  onChange={handleInputChange(index)}
                                  onWheel={(e) => e.target.blur()}
                                  className="w-full border rounded-md placeholder:text-sm p-1"
                                />
                              </div>
                              <div
                                className={`border p-2  ${
                                  index === 4
                                    ? "border-r-2 border-r-red-500"
                                    : ""
                                }`}
                              >
                                <input
                                  type="number"
                                  step="0.01"
                                  id="weight_of_empty_vessel"
                                  name="weight_of_empty_vessel"
                                  placeholder="Weight of Empty vessel"
                                  disabled
                                  value={
                                    formData?.[index]?.weight_of_empty_vessel
                                  }
                                  onChange={handleInputChange(index)}
                                  onWheel={(e) => e.target.blur()}
                                  className="w-full border rounded-md placeholder:text-sm p-1"
                                />
                              </div>
                              <div
                                className={`border p-2 border-b-2 border-b-red-500 ${
                                  index === 4
                                    ? "border-r-2 border-r-red-500"
                                    : ""
                                }`}
                              >
                                <input
                                  type="number"
                                  step="0.01"
                                  id="net_weight_fg_a"
                                  name="net_weight_fg_a"
                                  placeholder="Net weight - FG -(Kgs.)"
                                  disabled
                                  value={formData?.[index]?.net_weight_fg_a}
                                  onChange={handleInputChange(index)}
                                  onWheel={(e) => e.target.blur()}
                                  className="w-full border rounded-md placeholder:text-sm p-1"
                                />
                              </div>

                              <div
                                className={`border p-2  ${
                                  index === 4
                                    ? "border-r-2 border-r-red-500"
                                    : ""
                                }`}
                              >
                                <input
                                  type="number"
                                  step="0.01"
                                  id="excess_bowl_nos"
                                  name="excess_bowl_nos"
                                  placeholder="Excess Bowl nos."
                                  value={formData?.[index]?.excess_bowl_nos}
                                  onChange={handleInputChange(index)}
                                  onWheel={(e) => e.target.blur()}
                                  className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                                />
                              </div>
                              <div
                                className={`border p-2  ${
                                  index === 4
                                    ? "border-r-2 border-r-red-500"
                                    : ""
                                }`}
                              >
                                <input
                                  type="number"
                                  step="0.01"
                                  id="weight_of_1_filled_bowl"
                                  name="weight_of_1_filled_bowl"
                                  placeholder="Weight of 1 Filled bowl"
                                  value={
                                    formData?.[index]?.weight_of_1_filled_bowl
                                  }
                                  onChange={handleInputChange(index)}
                                  onWheel={(e) => e.target.blur()}
                                  className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                                />
                              </div>
                              <div
                                className={`border p-2 border-b-2 border-b-red-500 ${
                                  index === 4
                                    ? "border-r-2 border-r-red-500"
                                    : ""
                                }`}
                              >
                                <input
                                  type="number"
                                  step="0.01"
                                  id="bowl_filled_weight_b"
                                  placeholder="Bowl Filled Weight (Kgs.)"
                                  disabled
                                  name="bowl_filled_weight_b"
                                  value={
                                    formData?.[index]?.bowl_filled_weight_b
                                  }
                                  onChange={handleInputChange(index)}
                                  onWheel={(e) => e.target.blur()}
                                  className="w-full border rounded-md placeholder:text-sm p-1"
                                />
                              </div>

                              <div
                                className={`border  p-2 border-b-2  border-b-red-500 ${
                                  index === 4
                                    ? "border-r-2 border-r-red-500"
                                    : ""
                                }`}
                              >
                                <input
                                  type="number"
                                  step="0.01"
                                  id="total_weight"
                                  name="total_weight"
                                  placeholder="Total Weight (Kgs.)"
                                  disabled
                                  value={formData?.[index]?.total_weight}
                                  onChange={handleInputChange(index)}
                                  onWheel={(e) => e.target.blur()}
                                  className="w-full border rounded-md placeholder:text-sm p-1 bg-lime-300"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {(item?.measurements?.id == 2 ||
                          item?.measurements?.id == 5) && (
                          <>
                            <div
                              className={`border p-2 border-t-2 border-t-red-500 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <Select
                                classNamePrefix="select"
                                styles={{
                                  placeholder: (provided) => ({
                                    ...provided,
                                    fontSize: "0.80rem",
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: "#FFFAF0",
                                    fontSize: "16px",
                                  }),
                                }}
                                name="vessel_name"
                                value={formData?.[index].vessel_name}
                                onChange={(selectedOption, name) => {
                                  handleSelectChange(
                                    index,
                                    selectedOption,
                                    name
                                  );
                                }}
                                placeholder="Vessel Name"
                                options={vesselList.map((item) => ({
                                  value: item.id,
                                  label: item.name,
                                }))}
                                filterOption={(option, inputValue) => {
                                  return option.data.label
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase());
                                }}
                              />
                            </div>
                            <div
                              className={`border p-2  ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="empty_height"
                                name="empty_height"
                                placeholder="Empty height (cms)"
                                value={formData?.[index]?.empty_height}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div
                              className={`border p-2  ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="filled_height"
                                name="filled_height"
                                placeholder="Filled height (Cms.)"
                                value={formData?.[index]?.filled_height}
                                onChange={handleInputChange(index)}
                                onBlur={handleBlur(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div
                              className={`border p-2 border-b-2 border-b-red-500 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="vessel_volume"
                                placeholder="Vessel Volume (Ltrs) - A"
                                disabled
                                name="vessel_volume"
                                value={formData?.[index]?.vessel_volume}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>

                            <div
                              className={`border p-2  ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="excess_bowl_nos"
                                name="excess_bowl_nos"
                                placeholder="Excess Bowl nos."
                                value={formData?.[index]?.excess_bowl_nos}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div
                              className={`border p-2  ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="volume_of_1_filled_bowl"
                                name="volume_of_1_filled_bowl"
                                placeholder="Volume of 1 Filled bowl (ltrs.)"
                                value={
                                  formData?.[index]?.volume_of_1_filled_bowl
                                }
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div
                              className={`border p-2 border-b-2 border-b-red-500 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="bowl_filled_volume"
                                disabled
                                name="bowl_filled_volume"
                                placeholder="Bowl. Filled Volume (ltrs.) - B"
                                value={formData?.[index]?.bowl_filled_volume}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>

                            <div
                              className={`border p-2 border-b-2 border-b-red-500 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="total_volume"
                                placeholder="Total Volume (ltrs.)"
                                disabled
                                name="total_volume"
                                value={formData?.[index]?.total_volume}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-lime-300"
                              />
                            </div>
                          </>
                        )}
                        {(item?.measurements?.id == 3 ||
                          item?.measurements?.id == 6) && (
                          <>
                            <div
                              className={`border p-2 border-t-2 border-t-red-500 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="gross_weight"
                                name="gross_weight"
                                placeholder="Gross weight (Ice box+Rotli)"
                                value={formData?.[index]?.gross_weight}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div
                              className={`border p-2 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="weight_of_1_empty_bowl_icebox"
                                name="weight_of_1_empty_bowl_icebox"
                                placeholder="Weight of 1 empty Icebox / bowl"
                                // disabled
                                value={
                                  formData?.[index]
                                    ?.weight_of_1_empty_bowl_icebox
                                }
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div
                              className={`border p-2 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="no_of_bowl_icebox"
                                name="no_of_bowl_icebox"
                                placeholder="No. of Bowl/icebox"
                                value={formData?.[index]?.no_of_bowl_icebox}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div
                              className={`border p-2 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="total_weight_of_empty_bowl_icebox"
                                placeholder="Total Weight of empty Icebox / bowl"
                                disabled
                                name="total_weight_of_empty_bowl_icebox"
                                value={
                                  formData?.[index]
                                    ?.total_weight_of_empty_bowl_icebox
                                }
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div
                              className={`border p-2 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="net_weight_of_rotis"
                                placeholder="Net weight of rotis"
                                disabled
                                name="net_weight_of_rotis"
                                value={formData?.[index]?.net_weight_of_rotis}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div
                              className={`border p-2 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="weight_of_1_roti"
                                placeholder="Weight of 1 roti"
                                name="weight_of_1_roti"
                                value={formData?.[index]?.weight_of_1_roti}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div
                              className={`border p-2 border-b-2 border-b-red-500  ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="no_of_rotis_a"
                                placeholder="No of rotis(A)"
                                disabled
                                name="no_of_rotis_a"
                                value={formData?.[index]?.no_of_rotis_a}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>

                            <div
                              className={`border p-2 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="no_of_bowl_icebox_b"
                                placeholder="No of icebox / bowl"
                                name="no_of_bowl_icebox_b"
                                value={formData?.[index]?.no_of_bowl_icebox_b}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div
                              className={`border p-2 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="nos_in_1_bowl_icebox"
                                name="nos_in_1_bowl_icebox"
                                placeholder="Nos in 1 icebox / bowl"
                                value={formData?.[index]?.nos_in_1_bowl_icebox}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div
                              className={`border p-2 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="no_of_rotis_b"
                                placeholder="No.of rotis (B)"
                                disabled
                                name="no_of_rotis_b"
                                value={formData?.[index]?.no_of_rotis_b}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>

                            <div
                              className={`border p-2 border-t-2  border-t-red-500 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="no_of_rotis_c"
                                placeholder="No.of rotis (C)"
                                name="no_of_rotis_c"
                                value={formData?.[index]?.no_of_rotis_c}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>

                            <div
                              className={`border p-2 border-t-2 border-t-red-500 border-b-2 border-b-red-500 ${
                                index === 4 ? "border-r-2 border-r-red-500" : ""
                              }`}
                            >
                              <input
                                type="number"
                                step="0.01"
                                id="total_no_of_roti"
                                placeholder="Total No. Of Rotis"
                                disabled
                                name="total_no_of_roti"
                                value={formData?.[index]?.total_no_of_roti}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-lime-300"
                              />
                            </div>
                          </>
                        )}

                        {/* <div className="border p-2">
                                            <Select
                                                classNamePrefix="select"
                                                name="timeslot"  
                                                value={formData?.[index].timeslot} 
                                                onChange={(selectedOption, name) => {
                                                    handleSelectChange(index,selectedOption, name);
                                                }}                                     
                                                options={timeSlotList.map(item => ({
                                                    value: item.value,
                                                    label: item.label
                                                }))}
                                                filterOption={(option, inputValue) => {
                                                    return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                                                }}
                                            />
                                        </div>
                                        <div className="border p-2"><input type="text" id="requisition_no" name="requisition_no" value={formData?.[index]?.requisition_no} onChange={handleInputChange(index)} className="w-full border rounded-md placeholder:text-sm p-1" />	</div> */}

                        <div className="border p-2">
                          <button
                            onClick={(e) => {
                              handleSave(e, index);
                            }}
                            className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm p-1 mr-2"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ExcessScreen;
