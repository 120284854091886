
import React, { useState } from "react";

import { useLocation, Link } from 'react-router-dom';
import ReceipeForm from "../../../components/Form/Master/ReceipeForm";


export default function EditMenuitem() {

    const [ingredientList, setIngredientList] = useState([]);
    const location = useLocation();
    const detail = location.state;


    const handleExport = () => {
        const itemName = detail[0]?.item_name;
        const engName = detail[0]?.eng_name;

        const itemDisplayName = itemName || engName ? `${itemName ?? ''}${engName ? ` (${engName})` : ''}`.trim() : '-';
        const header = ["Id","Ingredients", "Qty", "Unit"];
        const itemNameArray = ["Item Name", itemDisplayName];
        
        const csv = [
            itemNameArray.join(','), // Item name row 
            "",
            header.join(','), // Header row
            ...detail[0].rows.map((item, index) => {
                const ingrd = ingredientList.find((v) => v.id == item.ingredient_id);
                let resultArray = [];
                resultArray.push(index + 1);
                resultArray.push(`${ingrd?.ingredient || ingrd?.eng_name ? `${ingrd?.ingredient ?? ''}${ingrd?.eng_name ? ` (${ingrd.eng_name})` : ''}`.trim() : '-'}`);
                resultArray.push(item.qty);
                resultArray.push(ingrd?.unit?.name ?? "-");
                return resultArray.join(',');
            })
        ].join('\n');
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', `${itemName+"_"+engName}_Receipe`);
        a.click();
    }
    const handelIngredientList = (list) => {
        setIngredientList(list);
    }

  return (
    <div className="p-4">
        <div className="flex justify-between items-center py-4 px-6">
            <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">Receipe Edit</h1>
            <div>
                <button onClick={handleExport} className="px-4 py-2 bg-blue-500 text-white rounded-md placeholder:text-sm mr-2">Export</button>
                <Link to="/ingredients/add">
                    <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Add Ingredient</button>
                </Link>
                <Link to="/receipe/list">
                    <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Back</button>
                </Link>
            </div>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg px-4 py-2">
           <ReceipeForm detail={detail} handelIngredientList={handelIngredientList} />
        </div>
    </div>
  )
}
