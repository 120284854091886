// routes/index.js

import React, { useContext, useEffect } from "react";
import {  Route, Routes } from "react-router-dom";

import Home from "../pages/Home";
import Login from "../pages/Auth/Login";
import IngredientsList from "../pages/Master/Ingredients/List";
import AddIngredient from "../pages/Master/Ingredients/AddIngredient";
import EditIngredient from "../pages/Master/Ingredients/EditIngredient";
import IngredientsCategoryList from "../pages/Master/Ingredients/Category/List";
import AddIngredientCategory from "../pages/Master/Ingredients/Category/AddCategory";
import EditIngredientCategory from "../pages/Master/Ingredients/Category/EditCategory";
import ItemsList from "../pages/Master/Item/List";
import AddItem from "../pages/Master/Item/AddItem";
import EditItem from "../pages/Master/Item/EditItem";
import ItemCategoryList from "../pages/Master/Item/Category/List";
import AddItemCategory from "../pages/Master/Item/Category/AddCategory";
import EditItemCategory from "../pages/Master/Item/Category/EditCategory";
import ReceipeList from "../pages/Master/Receipe/List";
import AddReceipe from "../pages/Master/Receipe/AddReceipe";
import EditReceipe from "../pages/Master/Receipe/EditReceipe";
import ConsumptionMasterList from "../pages/Master/ConsumptionMaster/List";
import AddConsumptionMaster from "../pages/Master/ConsumptionMaster/AddConsumptionMaster";
import EditConsumptionMaster from "../pages/Master/ConsumptionMaster/EditConsumptionMaster";
import VesselMasterList from "../pages/Master/VesselMaster/List";
import AddVesselMaster from "../pages/Master/VesselMaster/AddVesselMaster";
import EditVesselMaster from "../pages/Master/VesselMaster/EditVesselMaster";
import BowlMasterList from "../pages/Master/BowlMaster/List";
import AddBowlMaster from "../pages/Master/BowlMaster/AddBowlMaster";
import EditBowlMaster from "../pages/Master/BowlMaster/EditBowlMaster";
import TrollyMasterList from "../pages/Master/Trolly/TrollyMaster/List";
import AddTrollyMaster from "../pages/Master/Trolly/TrollyMaster/AddTrollyMaster";
import EditTrollyMaster from "../pages/Master/Trolly/TrollyMaster/EditTrollyMaster";
import TrollyTypeList from "../pages/Master/Trolly/TrollyType/List";
import AddTrollyType from "../pages/Master/Trolly/TrollyType/AddTrollyType";
import EditTrollyType from "../pages/Master/Trolly/TrollyType/EditTrollyType";
import MethodMasterList from "../pages/Master/MethodMaster/List";
import AddMethodMaster from "../pages/Master/MethodMaster/AddMethodMaster";
import EditMethodMaster from "../pages/Master/MethodMaster/EditMethodMaster";
import StoreList from "../pages/Master/Store/List";
import AddStore from "../pages/Master/Store/AddStore";
import EditStore from "../pages/Master/Store/EditStore";
import SessionMasterList from "../pages/Master/SessionMaster/List";
import AddSessionMaster from "../pages/Master/SessionMaster/AddSessionMaster";
import EditSessionMaster from "../pages/Master/SessionMaster/EditSessionMaster";
import EventMasterList from "../pages/Master/EventMaster/List";
import AddEventMaster from "../pages/Master/EventMaster/AddEventMaster";
import EditEventMaster from "../pages/Master/EventMaster/EditEventMaster";
import MealMasterList from "../pages/MealMaster/List";
import AddMealMaster from "../pages/MealMaster/AddMealMaster";
import EditMealMaster from "../pages/MealMaster/EditMealMaster";
import RawMaterial from "../pages/RawMaterial";
import ProductionEstimationCalc from "../pages/ProductionEstimationCalc";
import KitRequisitionScreen from "../pages/KitRequisitionScreen";
import ReceipePlanning from "../pages/ReceipePlanning";
import ProductionPlanningList from "../pages/ProductionPlanningList";
import RotliProductionList from "../pages/RotliProductionList";
import ProductionSupport from "../pages/ProductionSupport";
import FinishedGoods from "../pages/FinishedGoods";
import BowlScreen from "../pages/BowlScreen";
import ExcessScreen from "../pages/ExcessScreen";
import ShortageList from "../pages/Shortage/List";
import AddShortage from "../pages/Shortage/AddShortage";
import EditShortage from "../pages/Shortage/EditShortage";
import DishIssueList from "../pages/DishIssue/List";
import AddDishIssue from "../pages/DishIssue/AddDishIssue";
import EditDishIssue from "../pages/DishIssue/EditDishIssue";
import DishCountList from "../pages/DishCount/List";
import AddDishCount from "../pages/DishCount/AddDishCount";
import EditDishCount from "../pages/DishCount/EditDishCount";
import ReportMaster from "../pages/Reports/MasterReport";
import ReportSummary from "../pages/Reports/SummaryReport";
import ReportProduction from "../pages/Reports/ProductionReport";
import ReportExcess from "../pages/Reports/ExcessReport";
import RequirementList from "../pages/Veg/RequirementList";
import OrderList from "../pages/Veg/OrderList";
import PurchaseList from "../pages/Veg/PurchaseList";
import ReceiptList from "../pages/Veg/ReceiptList";
import HelmetWrapper from './HelmetWrapper';
import IssueList from "../pages/Veg/IssueList";
import IssueNetList from "../pages/Veg/IssueNetList";
import MainStoreIssueList from "../pages/MainStore/IssueList";
import ReturnReceiptList from "../pages/MainStore/ReturnReceiptList";

function AppRouter() {

  const routes = [
    { path: "/login", component: Login, title: "Login",  allowedRoles:[1, 2, 3, 4, 5, 6, 7, 8]  },
    { path: "/ingredients/list", component: IngredientsList, title: "Ingredients List", allowedRoles:[1,2] },
    { path: "/ingredients/add", component: AddIngredient, title: "Add Ingredient" ,  allowedRoles:[1,2]},
    { path: "/ingredients/edit/:id", component: EditIngredient, title: "Edit Ingredient",  allowedRoles:[1,2] },
    { path: "/ingredients_category/list", component: IngredientsCategoryList, title: "Ingredients Category List", allowedRoles:[1,2] },
    { path: "/ingredients_category/add", component: AddIngredientCategory, title: "Add Ingredient Category", allowedRoles:[1,2] },
    { path: "/ingredients_category/edit/:id", component: EditIngredientCategory, title: "Edit Ingredient Category", allowedRoles:[1,2] },
    { path: "/item/list", component: ItemsList, title: "Items List", allowedRoles:[1,2] },
    { path: "/item/add", component: AddItem, title: "Add Item", allowedRoles:[1,2] },
    { path: "/item/edit/:id", component: EditItem, title: "Edit Item", allowedRoles:[1,2] },
    { path: "/item_category/list", component: ItemCategoryList, title: "Item Category List", allowedRoles:[1,2] },
    { path: "/item_category/add", component: AddItemCategory, title: "Add Item Category", allowedRoles:[1,2] },
    { path: "/item_category/edit/:id", component: EditItemCategory, title: "Edit Item Category", allowedRoles:[1,2] },
    { path: "/receipe/list", component: ReceipeList, title: "Recipe List", allowedRoles:[1,2] },
    { path: "/receipe/add", component: AddReceipe, title: "Add Recipe", allowedRoles:[1,2] },
    { path: "/receipe/edit/:id", component: EditReceipe, title: "Edit Recipe", allowedRoles:[1,2] },
    { path: "/consumption_master/list", component: ConsumptionMasterList, title: "Consumption Master List", allowedRoles:[1] },
    { path: "/consumption_master/add", component: AddConsumptionMaster, title: "Add Consumption Master", allowedRoles:[1] },
    { path: "/consumption_master/edit/:id", component: EditConsumptionMaster, title: "Edit Consumption Master", allowedRoles:[1] },
    { path: "/vessel_master/list", component: VesselMasterList, title: "Vessel Master List", allowedRoles:[1,8] },
    { path: "/vessel_master/add", component: AddVesselMaster, title: "Add Vessel Master", allowedRoles:[1,8] },
    { path: "/vessel_master/edit/:id", component: EditVesselMaster, title: "Edit Vessel Master" , allowedRoles:[1,8]},
    { path: "/bowl_master/list", component: BowlMasterList, title: "Bowl Master List", allowedRoles:[1,8] },
    { path: "/bowl_master/add", component: AddBowlMaster, title: "Add Bowl Master", allowedRoles:[1,8] },
    { path: "/bowl_master/edit/:id", component: EditBowlMaster, title: "Edit Bowl Master", allowedRoles:[1,8] },
    { path: "/trolly_master/list", component: TrollyMasterList, title: "Trolley Master List", allowedRoles:[1,8] },
    { path: "/trolly_master/add", component: AddTrollyMaster, title: "Add Trolley Master", allowedRoles:[1,8] },
    { path: "/trolly_master/edit/:id", component: EditTrollyMaster, title: "Edit Trolley Master", allowedRoles:[1,8] },
    { path: "/trolly_type/list", component: TrollyTypeList, title: "Trolley Type List", allowedRoles:[1,8] },
    { path: "/trolly_type/add", component: AddTrollyType, title: "Add Trolley Type", allowedRoles:[1,8] },
    { path: "/trolly_type/edit/:id", component: EditTrollyType, title: "Edit Trolley Type", allowedRoles:[1,8] },
    { path: "/method_master/list", component: MethodMasterList, title: "Method Master List", allowedRoles:[1] },
    { path: "/method_master/add", component: AddMethodMaster, title: "Add Method Master", allowedRoles:[1] },
    { path: "/method_master/edit/:id", component: EditMethodMaster, title: "Edit Method Master", allowedRoles:[1] },
    { path: "/store/list", component: StoreList, title: "Store List", allowedRoles:[1] },
    { path: "/store/add", component: AddStore, title: "Add Store", allowedRoles:[1] },
    { path: "/store/edit/:id", component: EditStore, title: "Edit Store", allowedRoles:[1] },
    { path: "/session_master/list", component: SessionMasterList, title: "Session Master List", allowedRoles:[1] },
    { path: "/session_master/add", component: AddSessionMaster, title: "Add Session Master", allowedRoles:[1] },
    { path: "/session_master/edit/:id", component: EditSessionMaster, title: "Edit Session Master", allowedRoles:[1] },
    { path: "/meal_master/list", component: MealMasterList, title: "Meal Master List", allowedRoles:[1,2] },
    { path: "/meal_master/add", component: AddMealMaster, title: "Add Meal Master", allowedRoles:[1,2] },
    { path: "/meal_master/edit/:id", component: EditMealMaster, title: "Edit Meal Master", allowedRoles:[1,2] },
    { path: "/event_master/list", component: EventMasterList, title: "Event List", allowedRoles:[1,2] },
    { path: "/event_master/add", component: AddEventMaster, title: "Add Event Master", allowedRoles:[1,2] },
    { path: "/event_master/edit/:id", component: EditEventMaster, title: "Edit Event Master", allowedRoles:[1,2] },
    { path: "/raw_material/list", component: RawMaterial, title: "Production Estimation", allowedRoles:[1,2] },
    { path: "/production_estimation_calc", component: ProductionEstimationCalc, title: "RM Calculator", allowedRoles:[1,2] },
    { path: "/kit_requisition/list", component: KitRequisitionScreen, title: "Kit Requisition", allowedRoles:[1,2] },
    { path: "/receipe_planning/list", component: ReceipePlanning, title: "Receipe Planning List", allowedRoles:[1,2] },
    { path: "/production_planning/list", component: ProductionPlanningList, title: "Production Planning List", allowedRoles:[1,2] },
    { path: "/rotli_production/list", component: RotliProductionList, title: "Rotli Production List", allowedRoles:[1,7] },
    { path: "/production_support/list", component: ProductionSupport, title: "Production Support List", allowedRoles:[1,6] },
    { path: "/finished_good/list", component: FinishedGoods, title: "Finished Goods List", allowedRoles:[1,8] },
    { path: "/bowl_screen/list", component: BowlScreen, title: "Bowl List", allowedRoles:[1,8] },
    { path: "/excess_screen/list", component: ExcessScreen, title: "Excess List", allowedRoles:[1,8] },
    { path: "/shortage_screen/list", component: ShortageList, title: "Shortage List", allowedRoles:[1,8] },
    { path: "/shortage_screen/add", component: AddShortage, title: "Add Shortage", allowedRoles:[1,8] },
    { path: "/shortage_screen/edit/:id", component: EditShortage, title: "Edit Shortage", allowedRoles:[1,8] },
    { path: "/dish_issue/list", component: DishIssueList, title: "Dish Issue List", allowedRoles:[1,8] },
    { path: "/dish_issue/add", component: AddDishIssue, title: "Add Dish Issue", allowedRoles:[1,8] },
    { path: "/dish_issue/edit/:id", component: EditDishIssue, title: "Edit Dish Issue", allowedRoles:[1,8] },
    { path: "/dish_count/list", component: DishCountList, title: "Dish Count List", allowedRoles:[1,8] },
    { path: "/dish_count/add", component: AddDishCount, title: "Add Dish Count", allowedRoles:[1,8] },
    { path: "/dish_count/edit/:id", component: EditDishCount, title: "Edit Dish Count", allowedRoles:[1,8] },
    { path: "/reports/master", component: ReportMaster, title: "Report Master",allowedRoles:[1,2,8] },
    { path: "/reports/summary", component: ReportSummary, title: "Summary Master",allowedRoles:[1,2,8] },
    { path: "/reports/production", component: ReportProduction, title: "Report Production",allowedRoles:[1,2,8] },
    { path: "/reports/excess", component: ReportExcess, title: "Report Excess",allowedRoles:[1,2,8] },
    { path: "/requirement/list", component: RequirementList, title: "Requirement", allowedRoles:[1,4] },
    { path: "/order/list", component: OrderList, title: "Order", allowedRoles:[1,4] },
    { path: "/purchase/list", component: PurchaseList, title: "Purchase", allowedRoles:[1,4] },
    { path: "/receipt/list", component: ReceiptList, title: "Receipt", allowedRoles:[1,4]},
    { path: "/issue/list", component: IssueList, title: "Issue", allowedRoles:[1,4] },
    { path: "/issue_net/list", component: IssueNetList, title: "Issue(Net)", allowedRoles:[1,4] },
    { path: "/mian_store_issue/list", component: MainStoreIssueList, title: "Main Store Issue", allowedRoles:[1,3]},
    { path: "/return_receipt/list", component: ReturnReceiptList, title: "Return Receipt",allowedRoles:[1,3] },
  ];
  
  return (
    <Routes>
      {routes.length > 0 && routes.map((route, index) => (
  
        <Route
          key={index}
          path={route.path}
          // element={<route.component />}
          // onStart={() => setTitle(route.title)}
          element={
            <HelmetWrapper title={route.title} route={route}>
              <route.component />
            </HelmetWrapper>
          }
        />
      
      ))}
    </Routes>
  );
}

export default AppRouter;
