import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  baseUrl,
  timeList,
  rotliTypeList,
  rotiBatchSize,
} from "../comman/config";
import Select from "react-select";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { DateSessionFilter } from "../components/Filter/DateSessionFilter";
import toast, { Toaster } from "react-hot-toast";


function RotliProductionList() {
  const today = new Date().toISOString().split("T")[0];
  const [mealList, setMealList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [date, setDate] = useState(today);
  const [session, setSession] = useState("");
  const [formData, setFormData] = useState([]);
  const [totalPlannedQty, setTotalPlannedQty] = useState(0);
  const [totalRawMaterialQty, setTotalRawMaterialQty] = useState(0);

useEffect(() => {
    
  const initialFormData = Array.from({ length: rotiBatchSize }, (_, index) => ({
    batch: index + 1,
    rotli_machine: "",
    weight_of_kacho_lot: "",
    weight_of_mon_lot: "",
    gulla_weight:"",
    kacho_mon_lot_ratio: "",
    no_of_gulla:"",
    start_time:"",
    end_time:"",
    remarks: "",
  }));
  setFormData(initialFormData);
  }, [rotiBatchSize]);

  useEffect(() => {
    if (mealList.length > 0 && activeIndex != null) {
      getRMDetailForItem();
    }
  }, [mealList, activeIndex]);

  useEffect(() => {
    const sum = formData.reduce((total, obj) => {
      const qty = parseFloat(obj.weight_of_kacho_lot);
      return isNaN(qty) ? total : total + qty;
    }, 0);
    setTotalRawMaterialQty(sum);
  }, [formData]);

  useEffect(() => {
    if (mealList.length > 0 && activeIndex != null) {
      const itemId =
        mealList.find((item, index) => index === activeIndex).menu_item_id ??
        null;
      // console.log(itemId);
      const fetchDetail = async () => {
        const condition = `menu_item_id='${itemId}' AND date = '${date}' AND session='${session.value}'`;
        const response = await axios.get(
          `${baseUrl}/comman/list?table=rotli_production&condition=${condition}`
        );
        const apiDetail = response.data.data;
        getDetailDispaly(apiDetail);
      };
      fetchDetail();
    }
  }, [mealList, activeIndex]);

  const getDetailDispaly = async (detail) => {
    try {
      if (detail) {
        const initialFormData = Array.from({ length: rotiBatchSize }, (_, index) => ({
          batch: index + 1,
          rotli_machine: "",
          weight_of_kacho_lot: "",
          weight_of_mon_lot: "",
          gulla_weight:"",
          kacho_mon_lot_ratio: "",
          no_of_gulla:"",
          start_time:"",
          end_time:"",
          remarks: "",
        }));
        
        const updatedFormData = await Promise.all(initialFormData.map(async(initialItem) => {
          const matchingObject = detail.find(
            (object) => object.batch === initialItem.batch
          );
          
          if (matchingObject) {
            const rotliMachineId = matchingObject.rotli_machine;
            const rotliMachineItem = rotliTypeList.find((item) => item.id == rotliMachineId);
            const rotliMachineObject = rotliMachineItem
              ? { value: rotliMachineItem.id, label: rotliMachineItem.label }
              : { value: "", label: "" };

            const start_time = matchingObject.start_time;
            const timeslotItem = timeList.find( (item) => item.value == start_time);
            const timeslotObject = timeslotItem
              ? { value: timeslotItem.value, label: timeslotItem.label }
              : { value: "", label: "" };

            const end_time = matchingObject.end_time;
            const endTimeItem = timeList.find((item) => item.value == end_time);
            const endTimeSlotObject = endTimeItem ?  { value: endTimeItem.value, label: endTimeItem.label } : { value: "", label: "" };

            matchingObject.rotli_machine = rotliMachineObject;
            
            matchingObject.start_time = timeslotObject;
            matchingObject.end_time = endTimeSlotObject;
            
            
            
            return { ...initialItem,  ...matchingObject };
          }
           else {
           
          return {...initialItem}
          }
        }));
        setFormData(updatedFormData);
      } else {
       
        const initialFormData = Array.from({ length: rotiBatchSize }, (_, index) => ({
          batch: index + 1,
          rotli_machine: "",
          weight_of_kacho_lot: "",
          weight_of_mon_lot: "",
          gulla_weight:"",
          kacho_mon_lot_ratio: "",
          no_of_gulla:"",
          start_time:"",
          end_time:"",
          remarks: "",
        }));
        
        setFormData(initialFormData);
      }
    } catch (e) {
      console.log(e);
    }
  };


  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const getRMDetailForItem = async () => {
    const item_id = mealList.find((item, index) => index == activeIndex);
    const condition = `date = '${date}' AND session = '${session.value}' AND menu_item_id = '${item_id.menu_item_id}'`;
    const response = await axios.get(
      `${baseUrl}/comman/list?table=production_planning&condition=${condition}`
    );
    const sum = response.data.data.reduce((total, obj) => {
      const qty = parseFloat(obj.planned_batch_raw_qty);
      return isNaN(qty) ? total : total + qty;
    }, 0);
    setTotalPlannedQty(sum);
  };

  const handelFilterChange = async (date, session) => {
    setDate(date);
    setSession(session);
    if (date && session) {
      const condition = ` m.session='${session.value}' AND m.date='${date}'`;
      const response = await axios.get(
        `${baseUrl}/meal_master/item_list?condition=${condition}`
      );
      
      let filteredArray = response.data.data.filter(item => item.method == 3 || item.measurements.includes("3"));
      
      setMealList(filteredArray);
    }
  };

  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;
   
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        
        updatedFormData[index] = { ...updatedFormData[index], [name]: value };

        
        const ratio = updatedFormData[index].weight_of_kacho_lot > 0 ? (updatedFormData[index].weight_of_mon_lot / updatedFormData[index].weight_of_kacho_lot).toFixed(3) : 0;
        const no_of_gulla = updatedFormData[index].gulla_weight > 0 ? ((updatedFormData[index].weight_of_mon_lot / updatedFormData[index].gulla_weight) * 1000).toFixed(3) : 0;
        updatedFormData[index] = { ...updatedFormData[index], ["kacho_mon_lot_ratio"]: ratio, ["no_of_gulla"] : no_of_gulla };
        return updatedFormData;
      });
    
    
  };


  const handleSelectChange = async (index, data, option) => {
    
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          [option.name]: data,
        };
        return updatedFormData;
      });
    
  };

  const handleSave = async (e, index) => {
    e.preventDefault();

    const menu_item_id =
      mealList.find((item, i) => i == activeIndex)?.menu_item_id ?? 0;
    const combineData = {
      formData: formData[index],
      date: date,
      session: session,
      menu_item_id: menu_item_id,
    };
    console.log(combineData);
    try {
      // setPopupOpen(true);

      await axios.post(`${baseUrl}/rotli_production/batch`, combineData);
      toast.success("Data successfully saved!");
    } catch (error) {
      toast.error("Failed to submit the form.");
      // setPopupOpen(false);
      console.log(error);
    }
  };


  return (
    <div className="">
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
          Rotli Production
        </h1>
        <Toaster position="top-center" reverseOrder={false} />
        
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <DateSessionFilter handleFilterChange={handelFilterChange} />
        <div>
          {mealList.map((item, index) => (
            <div key={index} className="border-b mt-4">
              <div
                className="flex justify-between items-center p-4 cursor-pointer accordion-title bg-orange-400 border rounded"
                onClick={() => handleClick(index)}
              >
                <span>{`${item.item_name} (${item.eng_name})`}</span>
                {index === activeIndex ? (
                  <ChevronUpIcon className="w-6 h-6" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6" />
                )}
              </div>
              {index === activeIndex && (
                <div className="space-y-2 p-4 bg-white">
                  <div className="flex justify-between">
                    <div className="lg:w-96">
                      
                      <div className="flex flex-wrap justify-between">
                        <div className="sm:text-sm lg:text-base font-bold">
                          Planned Raw Material Qty (Kgs.) :
                        </div>
                        <div>
                          {totalPlannedQty ?? 0}
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <div className="sm:text-sm lg:text-base font-bold">
                          Total Actual Raw Material Qty. (Kgs.) :
                        </div>
                        <div>{totalRawMaterialQty ?? 0}</div>
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <div className="sm:text-sm lg:text-base font-bold">
                          Pending Actual Raw Material Qty. (Kgs.) :
                        </div>
                        <div>
                          {isNaN(
                            totalRawMaterialQty - totalPlannedQty
                          )
                            ? 0
                            : totalRawMaterialQty -
                              totalPlannedQty}
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between bg-lime-300">
                        <div className="sm:text-sm lg:text-base font-bold">
                        કુલ રોટલી (નંગ)
                        </div>
                        <div>
                          {formData.reduce((total, obj) => {
    const qty = parseFloat(obj.no_of_gulla);
    return isNaN(qty) ? total : total + qty;
  }, 0).toFixed(0) ?? 0}
                        </div>
                      </div>
                    </div>
                   
                  </div>
                  <div className="flex overflow-x-auto">
                    {/* <div className="flex mt-10 flex-col">
                      <div className="w-32 font-bold mt-1">Batch</div>
                      <div className="w-52 font-bold mt-6">મશીન નં.</div>
                      <div className="w-52 font-bold mt-7">કાચો લોટનું વજન(Kg) *</div>
                      <div className="w-52 font-bold mt-8">મોણવાળો લોટ નું વજન (Kg) *</div>
                      <div className="w-52 font-bold mt-8">ગુલ્લા નું વજન (Gram) *</div>
                      <div className="w-52 font-bold mt-7">કાચો થી મોણવાળો લોટ નો ratio</div>
                      <div className="w-52 font-bold mt-7">આશરે ગુલ્લા (નંગ)</div>
                      <div className="w-52 font-bold mt-7">બેચ શરૂઆત સમય</div>
                      <div className="w-52 font-bold mt-7">બેચ પુરા થયા સમય</div>
                      <div className="w-52 font-bold mt-7">નોંધ/રીમાર્કસ</div>
                      <div className="w-20 font-bold "></div>
                    </div> */}
                    {Array.from({ length: rotiBatchSize }, (_, index) => (
                      <div
                        key={index}
                        className="gap-1 mb-4 mt-10 ms-2 "
                      >
                        <div className="border p-2 w-40">{index + 1}</div>
                        <div className="border p-2 w-40">
                          <Select
                            classNamePrefix="select"
                            name="rotli_machine"
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: "0.80rem",
                              }),
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: "#FFFAF0",
                                fontSize: "16px",
                              }),
                            }}
                            value={formData?.[index]?.rotli_machine}
                            onChange={(selectedOption, name) => {
                              handleSelectChange(index, selectedOption, name);
                            }}
                            placeholder="Machine no."
                            // className="w-40"
                            options={rotliTypeList.map((item) => ({
                              value: item.id,
                              label: item.label,
                            }))}
                            filterOption={(option, inputValue) => {
                              return option.data.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                          />
                        </div>
                        <div className="border p-2 w-40">
                          <input
                            type="number"
                            step="0.01"
                            id="weight_of_kacho_lot"
                            placeholder="કાચો લોટનું વજન(Kg) *"
                            name="weight_of_kacho_lot"
                            value={formData?.[index]?.weight_of_kacho_lot ?? ""}
                            onChange={handleInputChange(index)}
                            onWheel={(e) => e.target.blur()}
                            className="w-full p-1 border rounded-md placeholder:text-sm bg-orange-50"
                          />
                        </div>
                        <div className="border p-2 w-40">
                          <input
                            type="number"
                            step="0.01"
                            id="weight_of_mon_lot"
                            placeholder="મોણવાળો લોટ નું વજન (Kg) *"
                            name="weight_of_mon_lot"
                            value={formData?.[index]?.weight_of_mon_lot ?? ""}
                            onChange={handleInputChange(index)}
                            onWheel={(e) => e.target.blur()}
                            className="w-full p-1 border rounded-md placeholder:text-sm bg-orange-50"
                          />
                        </div>
                        <div className="border p-2 w-40">
                          <input
                            type="number"
                            step="0.01"
                            id="gulla_weight"
                            placeholder="ગુલ્લા નું વજન (Gram) *"
                            name="gulla_weight"
                            value={formData?.[index]?.gulla_weight ?? ""}
                            onChange={handleInputChange(index)}
                            onWheel={(e) => e.target.blur()}
                            className="w-full p-1 border rounded-md placeholder:text-sm bg-orange-50"
                          />
                        </div>
                        <div className="border p-2 w-40">
                          <input
                            type="number"
                            step="0.01"
                            id="kacho_mon_lot_ratio"
                            placeholder="કાચો થી મોણવાળો લોટ નો ratio"
                            name="kacho_mon_lot_ratio"
                            value={formData?.[index]?.kacho_mon_lot_ratio ?? ""}
                            disabled
                            // onChange={handleInputChange(index)}
                            className="w-full p-1 border rounded-md placeholder:text-sm"
                          />
                        </div>
                        <div className="border p-2 w-40">
                          <input
                            type="number"
                            step="0.01"
                            id="no_of_gulla"
                            placeholder="આશરે ગુલ્લા (નંગ)"
                            name="no_of_gulla"
                            value={formData?.[index]?.no_of_gulla ?? ""}
                            disabled
                            // onChange={handleInputChange(index)}
                            className="w-full p-1 border rounded-md placeholder:text-sm"
                          />
                        </div>
                        <div className="border p-2 w-40">
                        <Select
                            classNamePrefix="select"
                            name="start_time"
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: "0.80rem",
                              }),
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: '#FFFAF0', 
                                fontSize: '16px', 
                              }),
                            }}
                            value={formData?.[index]?.start_time}
                            onChange={(selectedOption, name) => {
                              handleSelectChange(index, selectedOption, name);
                            }}
                            placeholder="Start Time"
                            // className="placeholder:text-sm ms-2 "
                            options={timeList.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                            filterOption={(option, inputValue) => {
                              return option.data.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                          />
                        </div>
                        <div className="border p-2 w-40">
                          
                          
                          <Select
                            classNamePrefix="select"
                            name="end_time"
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: "0.80rem",
                              }),
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: '#FFFAF0', 
                                fontSize: '16px', 
                              }),
                            }}
                            value={formData?.[index]?.end_time}
                            onChange={(selectedOption, name) => {
                              handleSelectChange(index, selectedOption, name);
                            }}
                            placeholder="End Time"
                            // className="placeholder:text-sm ms-2"
                            options={timeList.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                            filterOption={(option, inputValue) => {
                              return option.data.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                          />
                        </div>
                        
                        <div className="border p-2 w-40">
                          <input
                            type="text"
                            placeholder="Remarks"
                            id="remarks"
                            name="remarks"
                            value={formData?.[index]?.remarks}
                            onChange={handleInputChange(index)}
                            className="w-full border rounded-md p-1 placeholder:text-sm bg-orange-50"
                          />
                        </div>
                        
                        <div className="border p-2">
                          <button
                            onClick={(e) => {
                              handleSave(e, index);
                            }}
                            className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm mr-2"
                          >
                            Save
                          </button>
                          
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RotliProductionList;
