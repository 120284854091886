import React, { useState, useEffect } from "react";
import axios from "axios";
import { saveAs } from 'file-saver';
import { baseUrl, getDateWithDay, requirementStatusList } from "../../comman/config";
import { VegRequirementFilter } from "../../components/Filter/VegRequirementFilter.js";
import toast, { Toaster } from "react-hot-toast";
import IngredientBatchModel from "../../components/IngredientBatchModel.js";
import Select from 'react-select';
import Loader from "../../components/Loader.js";
import NoRecordsFound from "../../components/NoRecordsFound.js";
import OrderIngredientModel from "../../components/OrderIngredientModel.js";
// import Pagination from "react-js-pagination";

function PurchaseList() {
  const today = new Date().toISOString().split("T")[0];
  const [ingredientPurchaseList, setIngredientPurchaseList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [ingredient, setIngredient] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [purchaseStatus, setPurchaseState] = useState({});
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [orderIngedientList, setOrderIngredientList] = useState(null);
  const [itemOrderList, setItemOrderList] = useState(null);
  const [methodList, setMethodList] = useState([]);
  const [ingredientList, setIngredientList] = useState([]);
  const [loading, setLoading] = useState(false)
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  useEffect(() => {
    getMethodList()
  }, []);
useEffect(() => {
  getIngredientPurchaseList();
}, [fromDate, toDate, ingredient, purchaseStatus])

useEffect(()=>{
  console.log(ingredientPurchaseList)
  getIngredientList()
},[ingredientPurchaseList])

  const handelFilterChange = async (from_date, to_date, session, item, requirement_status,order_status, order_id, purchase_status, receipt_status, issue_status, ingredient) => {
    
        setFromDate(from_date);
        setToDate(to_date);
        setIngredient(ingredient); 
       setPurchaseState(purchase_status);
  };

  const getMethodList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=method_master&condition=status=1`
    );
    setMethodList(response.data.data);
  };
  const getIngredientList = async () => {
    const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`);
    const existingIngredientIds = ingredientPurchaseList.map(item => item.ingredient_id);
    const list = response.data.data.filter((item) => item.status == 1 && item?.store?.id == 3 && !existingIngredientIds.includes(item.id));
    setIngredientList(list);
  };
  const getIngredientPurchaseList = async() => {
    setLoading(true)
    setNoRecordsFound(false)
    const response = await axios.post(`${baseUrl}/purchase/list`,
      { from_date : fromDate,
        to_date :toDate,
        ingredient : ingredient?.value,
        purchase_status : purchaseStatus?.value
       
    });
    const updatedArray = response?.data?.data.map(item => ({
      ...item,         // Spread existing properties
      purchase_qty: item.purchase_qty ?? "", 
      pur_pack_nos: item?.pur_pack_nos ?? 0,        // Add new key-value pair
      diff_qty : item.purchase_qty - item.total_order ?? 0
  }));
    setIngredientPurchaseList(updatedArray);
    setNoRecordsFound(updatedArray.length === 0);
    setLoading(false)
  }

  
  const handleSubmit = async() => {
    if(selectedRows.length == 0){
      alert("Please select at least one row");
      setSelectedRows([]);
      return;
    }
    console.log(selectedRows);
    if(selectedRows.some(item => item.purchase_qty === "")){
      alert("One or more items have an empty purchase qty value.");
      setSelectedRows([]);
      return;
    }
    if(selectedRows.some(item => item.ingredient_id === null)){
      alert("One or more items have an empty ingredient value.");
      setSelectedRows([])
      return
    }
    if(selectedRows.length > 0){
        try{
          if(window.confirm(`આ શાકભાજી "પરચેઝ" થવા જઈ રહી છે. હવે પછી "પરચેઝ" માં કોઈ સુધારો થઇ શકશે નહી .
            કોઈ કારણસર કોઈ સુધારો કરવાનો થાય તો "શાકભાજી પરચેઝ (ખરીદ) ટીમ" ને જાણ કરવી.
            શું તમે "પરચેઝ" કરવા માંગો છો ?`))
          {
            const response = await axios.post(`${baseUrl}/purchase/ingredient_purchase`,
              { ingredientsList : selectedRows
                });
                const msg = response.data.msg;
                toast.success(msg);
                getIngredientPurchaseList();
          }
        }catch (error) {
            toast.error("Failed to add item. Please try again.");
        }
    }
    setSelectedRows([])
  };

  const handleSave = async() => {
    if(selectedRows.length == 0){
      alert("Please select at least one row");
      setSelectedRows([]);
      return;
    }
    console.log(selectedRows);
    if(selectedRows.some(item => item.purchase_qty === "")){
      alert("One or more items have an empty purchase qty value.");
      setSelectedRows([]);
      return;
    }
    if(selectedRows.some(item => item.ingredient_id === null)){
      alert("One or more items have an empty ingredient value.");
      setSelectedRows([])
      return
    }
    if(selectedRows.length > 0){
      try{
        const response = await axios.post(`${baseUrl}/purchase/ingredient_save`,
          { 
            ingredientsList : selectedRows
          });
          toast.success("Data save sucessfully.");
          getIngredientPurchaseList();
      }catch (error) {
        toast.error("Failed to add item. Please try again.");
      }
    }
    setSelectedRows([])
  }
  const handleExport = async(e) => {
    e.preventDefault();
    try{
      const headerRow = ["Ingredient", "Total Order Qty", "Total Purhcase Qty", "Pur Pack Nos", "Diff Qty", "Purchase Status", "Purchase Id"];
     
      const csv = [headerRow].concat(
        ingredientPurchaseList.map(item => {
             // Replace status values with "active" or "inactive"
            const status = item.purchase_status == 1 ? "Pending Purchase" : (item.purchase_status == 2 ? "Pending Purchase" : "Purchased");
             // Create an array of values, including the modified status
             return [item.ingredient, item.total_order,item?.purchase_qty ?? '', item?.pur_pack_nos ?? 0, item.diff_qty,status,item.purchase_id].join(',');
           })).join('\n');
           
           
           // Create a Blob containing the CSV data
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      
        // Trigger the file download using file-saver
        saveAs(blob, 'purchase.csv');
    }catch (error) {
      toast.error("Failed to export item. Please try again.");
    }
  }


  const handleAddNewIngredientRow = () => {
    setIngredientPurchaseList([...ingredientPurchaseList, {
      ingredient: '',
      ingredient_obj:{},
      ingredient_id: null,
      total_order: 0,
      purchase_qty: '',
      pur_pack_nos: '',
      diff_qty: 0,
      purchase_status: 1,
      isNew: true
    }]);
  };

  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;
    
    setIngredientPurchaseList((prevFormData) => {
      if(name === "pur_pack_nos"){
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = { ...updatedFormData[index], [name]: value };
        return updatedFormData;
      }else{
        const updatedFormData = [...prevFormData];
        const total_order = updatedFormData[index].total_order;
        const diff_qty = (value - total_order).toFixed(2);
           updatedFormData[index] = { ...updatedFormData[index], [name]: value, diff_qty : diff_qty };
         return updatedFormData;
      }
    });
  };

  const handleToggleSelect = (index) => {
    const updatedSelectedRows = selectedRows.includes(ingredientPurchaseList[index])
        ? selectedRows.filter(item => item !== ingredientPurchaseList[index]) // Deselect
        : [...selectedRows, ingredientPurchaseList[index]]; // Select

   setSelectedRows(updatedSelectedRows);
    

};
const handleSwitchSelectAll = () => {
  if (selectAll) {
      setSelectedRows([]);
  } else {
      setSelectedRows(ingredientPurchaseList.filter((item) => item.purchase_status == 1)); // Select all rows
  }
  setSelectAll(!selectAll);
};

  const displayIngredientDetail = async(e, item) => {
    e.preventDefault();
    setLoading(true)
    let condition = `ingredient_id = '${item.ingredient_id}' AND order_status = 3`;
    if(fromDate == toDate){
      condition += ` AND date = '${fromDate}' `;
    }else if(fromDate && toDate){

      condition += ` AND (date >= '${fromDate}' AND  date  <= '${toDate}') `;
    }
    
    const response = await axios.get(
        `${baseUrl}/comman/list?table=item_requirement_summary&condition=${condition}`
    );
    const orderList = response.data.data;
    setItemOrderList(orderList);
    setLoading(false)
    setShowModal(true);
  }

  const clearItem = () => {
    setSelectedRows([]);
    setItemOrderList(null);
    setOrderIngredientList(null);
    getIngredientPurchaseList();
  };

  const handleNewIngredientChange = (selectedOptions,index) => {
    // setNewIngredient((prev)=>({...prev,ingredient:ingredient}))
    console.log(selectedOptions)
    const updatedList =   [...ingredientPurchaseList]
    updatedList[index].ingredient_obj = selectedOptions
    updatedList[index].ingredient = selectedOptions.ingredient
    updatedList[index].ingredient_id = selectedOptions.value
    setIngredientPurchaseList(updatedList)
  }

  return (
    <div className="p-4">
      {loading && <Loader/>}
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
         Purchase
        </h1>
        
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <VegRequirementFilter handleFilterChange={handelFilterChange} page="purchase" />
        
       
       
      </div>
      {noRecordsFound && <NoRecordsFound/>}
      {ingredientPurchaseList.length > 0 && <div className="overflow-visible">
          <table className="w-full mt-10 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-2 py-1 border border-black text-center "><input type="checkbox" className="newrow checkboxAll"  onChange={handleSwitchSelectAll} checked={selectAll} /></th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Ingredient</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Total Order Qty</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Total Purchase Qty</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Pur Pack Nos.</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Diff Qty</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Purchase Status</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Purchase ID</th>
                
              </tr>
            </thead>
            <tbody>
              {ingredientPurchaseList.map((item, index) => (
                <tr key={index} className="border border-black-b">
                  {!(item.purchase_status == 3 && item.receipt_status == 3) && <td className="px-2 py-1 border border-black text-center"><input type="checkbox" checked={selectedRows.includes(item)} onChange={() => handleToggleSelect(index)} /></td>}
                  {(item.purchase_status == 3 && item.receipt_status == 3) && <td className="px-2 py-1 border border-black text-center"></td>}
                  <td className="px-2 py-1 border border-black text-center">
                    {item.isNew ? (
                      <Select
                      // isMulti
                      classNamePrefix="select"
                      className='basic-multi-select'
                      value={item.ingredient_obj}
                      name="ingredient"
                      onChange={(selectedOptions) => handleNewIngredientChange(selectedOptions,index)}
                      options={ingredientList.map((item) => ({
                          value: item.id,
                          label: `${item.ingredient} (${item.eng_name})`,
                          unit:item.unit?.id,
                          ingredient: `${item.ingredient}`,
                          eng_name: item.eng_name,
                        }))}
                      placeholder="Ingredient List"
                      filterOption={(option, inputValue) => {
                          return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                      }}
                  />
                    ): (item.ingredient)}
                    
                  </td>
                  <td className="px-2 py-1 border border-black text-center"><a href="#" className="underline text-blue-500" onClick={(e) => displayIngredientDetail(e, item)}>{item.total_order}</a></td>
                  <td className="px-2 py-1 border border-black text-center">
                    <input type="number" 
                      id="purchase_qty" 
                      name="purchase_qty" 
                      disabled={item.purchase_status == 3 && item.receipt_status == 3}
                      value={item?.purchase_qty ?? ''} 
                      onChange={handleInputChange(index)}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => {
                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                          e.preventDefault(); // Disable up and down arrow keys
                        }
                      }}  
                      className="px-2 py-1 border rounded-md w-20 bg-orange-50"
                    />
                  </td>
                  <td className="px-2 py-1 border border-black text-center">
                    <input type="number" 
                      id="pur_pack_nos" 
                      name="pur_pack_nos" 
                      disabled={item.purchase_status == 3 && item.receipt_status == 3}
                      value={item?.pur_pack_nos ?? 0} 
                      onChange={handleInputChange(index)}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => {
                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                          e.preventDefault(); // Disable up and down arrow keys
                        }
                      }} 
                      className="px-2 py-1 border rounded-md w-20 bg-orange-50"
                    />
                  </td>
                  <td  className={`px-2 py-1 border border-black text-center ${item.diff_qty < 0 ? 'bg-yellow-300 text-black' : ''}`}>{item.diff_qty}</td>
                  <td className="px-2 py-1 border border-black text-center">
                   {item.purchase_status == 1 ? "Pending Purchase" : (item.purchase_status == 2 ? "Pending Purchase" : "Purchased")}
                  </td>
                  <td className="px-2 py-1 border border-black text-center">{item.purchase_id}</td>
                  
                </tr>
              ))}
            </tbody>
          </table>
          
        </div>}
        <IngredientBatchModel showModal={showModal} setShowModal={setShowModal} detailList={itemOrderList} clearItem={clearItem} type={2} />
        {ingredientPurchaseList.length > 0 && <div className='m-5'>
        
        <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
        >
          Save
        </button>
        <button
            onClick={handleAddNewIngredientRow}
            className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
        >
          Add Ingredient
        </button>
        <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-rose-400 text-white rounded-md mr-4 hover:bg-rose-300 focus:outline-none focus:ring focus:ring-rose-300"
        >
            Purchase
        </button>
        <button
            onClick={handleExport}
            className="px-4 py-2 bg-green-500 text-white rounded-md mr-4 hover:bg-rose-600 focus:outline-none focus:ring focus:ring-rose-200"
        >
          Export
        </button>
                </div>}
    </div>
  );
}

export default PurchaseList;
