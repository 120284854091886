import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import {baseUrl } from "../../../comman/config";
import { useNavigate, Link } from 'react-router-dom';

export default function Form({detail}) {
   
    const [formData, setFormData] = useState({
        menuitem_eng : '',
        menuitem_guj : '',
        menuitem_category : '',
        menuitem_unit : ''
    });
    const [validationErrors, setValidationErrors] = useState({
        menuitem_eng: false,
        menuitem_guj: false,
        menuitem_category: false,
        menuitem_unit: false,

    });
    const [menuitemCategory, setMenuitemCategory] = useState([]);
    const [methodList, setMethodList] = useState([]);
    const [popupOpen , setPopupOpen] = useState(false);
    const formRef = useRef(null);
    
    
    const navigate = useNavigate();
    useEffect(() => {
        getMenuitemCategory();
        getMethodList();
    }, []);

    useEffect(() => {
        if(detail){
            setFormData({
                menuitem_eng: detail[0].eng_name || '',
                menuitem_guj: detail[0].item_name || '',
                menuitem_category: detail[0]?.category?.id || '',
                menuitem_unit: detail[0]?.method?.id || '',
            });
        }
        // console.log(detail[0]);
    }, [detail]);

    const getMenuitemCategory = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=item_category_master&condition=status=1`);
        setMenuitemCategory(response.data.data);
    }
    
    const getMethodList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=method_master&condition=status=1`);
        setMethodList(response.data.data);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const gujaratiRegex = /^[\u0A80-\u0AFF\s]+$/;
        const gujaratiRegex = /^[\u0A80-\u0AFF\s()+\-]*$/;
        const newValidationErrors = {
            menuitem_eng: formData.menuitem_eng === '',
            menuitem_guj: formData.menuitem_guj === '' || !gujaratiRegex.test(formData.menuitem_guj),
            menuitem_category : formData.menuitem_category === '',
            menuitem_unit : formData.menuitem_unit === '',
        };
        setValidationErrors(newValidationErrors);
        const hasErrors = Object.values(newValidationErrors).some((error) => error);
        console.log(formData);
        if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }else {
            let url;
            try {
                setPopupOpen(true);
                if(detail){
                    url = `item/edit/${detail[0].id}`;
                }else{
                    url = "item/add";
                }
                await axios.post(`${baseUrl}/${url}`,{
                    formData: formData,
                });
                setPopupOpen(false);
                navigate("/item/list");
            } catch (error) {

                setPopupOpen(false);
                console.log(error);
                
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
    
            }));
      
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

  return (
 
 <form ref={formRef}>
                <div className="space-y-12">
                    <div>
                        <div className="mt-10 space-y-8 lg:space-y-0 lg:flex lg:gap-x-5 lg:flex-wrap ">
                            <div className="sm:col-span-2">
                                <label htmlFor="menuitem_eng" className="block text-sm font-medium leading-6 text-gray-900">Menuitem Name (Eng)</label>
                                <div className="mt-2">
                                    <input
                                      type="text"
                                      name="menuitem_eng"
                                      id="menuitem_eng"
                                      placeholder='Menuitem Name (Eng)'
                                      value={formData.menuitem_eng}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.menuitem_eng ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.menuitem_eng && <div className="text-red-500">Please enter a english name.</div>}
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="menuitem_guj" className="block text-sm font-medium leading-6 text-gray-900">Menuitem Name (Guj)</label>
                                <div className="mt-2">
                                    <input
                                      id="menuitem_guj"
                                      name="menuitem_guj"
                                      type="text"
                                      placeholder='Menuitem Name (Guj)'
                                      value={formData.menuitem_guj}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.menuitem_guj ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.menuitem_guj && <div className="text-red-500">Please enter a gujarati name.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="menuitem_category" className="block text-sm font-medium leading-6 text-gray-900">Menuitem Category</label>
                                <div className="mt-2">
                                    
                                    <select 
                                      id="menuitem_category"
                                      name="menuitem_category"
                                      type="text"
                                      value={formData.menuitem_category}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.menuitem_category ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}>
                                        <option value="">Select a Category</option>
                                        {menuitemCategory.map((item) => (
                                            <option value={item.id} key={item.id}>{item.name} ({item.eng_name})</option>
                                        ))};
                                    </select>
                                    
                                </div>
                                {validationErrors.menuitem_category && <div className="text-red-500">Please select a category.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="menuitem_unit" className="block text-sm font-medium leading-6 text-gray-900">Default Method</label>
                                <div className="mt-2">
                                <select 
                                      id="menuitem_unit"
                                      name="menuitem_unit"
                                      type="text"
                                      value={formData.menuitem_unit}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.menuitem_unit ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}>
                                        <option value="">Select a Unit</option>
                                        {methodList.map((item) => (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        ))};
                                    </select>
                                   
                                </div>
                                {validationErrors.menuitem_unit && <div className="text-red-500">Please select a unit.</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <Link to="/item/list">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                    </Link>
                    <button
                    onClick={handleSubmit}
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                </div>
            </form>
            )
        }