// RequirementModel.js
import React, {useState, useEffect} from "react";
import axios from "axios";
import {baseUrl} from "../comman/config";
import toast from "react-hot-toast";

const RequirementModel = ({ showModal, setShowModal, item, ingredientsList,lockScreen, clearItem  }) => {
  const [itemIngredientList , setItemIngredientList] = useState([]);
  useEffect(() => {
    if (showModal) {
      setItemIngredientList(ingredientsList);
    }
  }, [showModal, ingredientsList]);

  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;
    
    setItemIngredientList((prevFormData) => {
      const updatedFormData = [...prevFormData];
      if(name === "wastage"){
        const gross_qty = (parseFloat(updatedFormData[index].required_qty) + (parseFloat(updatedFormData[index].required_qty) * (value / 100))).toFixed(3);
        updatedFormData[index] = { ...updatedFormData[index], [name]: value, gross_required_qty : gross_qty };
      }else{
        updatedFormData[index] = { ...updatedFormData[index], [name]: value };
      }
      return updatedFormData;
    });
  };

  const handleAddItem = async() => {
    try{
      const response = await axios.post(`${baseUrl}/requirement/ingredient_update`,
        { ingredientsList : itemIngredientList,
          item: item});
          toast.success("Item added successfully!");
          handleClose();
    }catch (error) {
      toast.error("Failed to add item. Please try again.");
    }
    
  }

  const handleSumitForOrder = async() => {
    try{
      if(window.confirm(`આ શાકભાજી "ઓર્ડર" માટે મોકલી રહ્યા છીએ. હવે પછી આ આઈટમ માટેની શાકભાજી ની જરૂરીયાત" માં કોઈ સુધારો થઇ શકશે નહી .
કોઈ કારણસર કોઈ સુધારો કરવાનો થાય તો શાકભાજી "ઓર્ડેર" કરતી સમયે  ફેરફાર કરી લેવા.
શું તમે "ઓર્ડર" માટે આગળ મોકલવા  માંગો છો ?`)){
        const response = await axios.post(`${baseUrl}/requirement/submit_for_order`,
          { ingredientsList : itemIngredientList,
            item: item});
            toast.success("Submit for order successfully!");
            handleClose();
      }
      
    }catch (error) {
      toast.error("Failed to submit for order. Please try again.");
    }
  }
    

  const handleClose = () => {
    setShowModal(false);
    clearItem(); // Clear the selected item when closing the modal
  };
  if (!showModal) return null;
  
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full max-w-3xl mx-auto my-6 px-4 sm:px-6 lg:px-8">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  
                    <span><strong>Item:</strong> {item?.eng_name}</span>
                    <span><strong>Kit: </strong>{item?.kit_no}</span>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={handleClose}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                
                <div className="relative p-6 flex-auto max-h-[60vh] md:max-h-[70vh] overflow-y-auto">
                  <table className="border w-full text-xs sm:text-sm md:text-base">
                    <thead>
                      <tr>
                        <th className="p-2">Ingredient</th>
                        {/* <th className="p-2">Unit</th> */}
                        <th className="p-2">Required Qty</th>
                        <th className="p-2">Avg. Wastage (%)</th>
                        <th className="p-2">Gross Required Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemIngredientList.map((item, index) =>(
                        <tr key={index}>
                          <td className="p-2 text-center">{item?.ingredient}</td>
                          {/* <td className="p-2 text-center">{item.unitname}</td> */}
                          <td className="p-2 text-center">{item?.required_qty}</td>
                          <td className="p-2 text-center">
                            <input type="number" 
                              id="wastage" 
                              name="wastage" 
                              value={item?.wastage} 
                              onChange={handleInputChange(index)}
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={(e) => {
                                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                  e.preventDefault(); // Disable up and down arrow keys
                                }
                              }} 
                              className="px-2 py-1 border rounded-md w-20 bg-orange-50"
                              disabled={lockScreen}
                            />
                          </td>
                          <td className="p-2 text-center">
                            <input type="number" 
                              id="gross_required_qty" 
                              name="gross_required_qty" 
                              value={item?.gross_required_qty} 
                              onChange={handleInputChange(index)}
                              disabled={lockScreen}
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={(e) => {
                                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                  e.preventDefault(); // Disable up and down arrow keys
                                }
                              }} 
                              className="px-2 py-1 border rounded-md w-20 bg-orange-50"
                              
                            /></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
                {/*footer*/}
                <div className="flex items-center justify-between p-6 border-t border-solid border-blueGray-200 rounded-b">
                {!lockScreen &&<div className="flex-grow">
     <button
      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-1.5 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
      type="button"
      // disabled={item.ingredient_added == 2}
      onClick={() => handleAddItem()}
    >
      Save Changes
    </button>
  </div>}
  <div className="flex space-x-2">
    <button
      className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
      type="button"
      onClick={handleClose}
    >
      Close
    </button>
    {!lockScreen && <button
      className="bg-rose-500 text-white active:bg-rose-600 font-bold uppercase text-sm px-1.5 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
      type="button"
      // disabled={item.ingredient_added == 2}
      onClick={() => handleSumitForOrder()}
    >
      Submit for Order
    </button>}
  </div>
</div>

              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default RequirementModel;
