import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { baseUrl } from "../../comman/config";
import { useNavigate, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

export default function Form({ detail }) {
  const today = new Date().toISOString().split("T")[0];
  const [formData, setFormData] = useState({
    currentDate:today,
    date: today,
    session: "",
    // menu_category_id : '',
    // menu_item_id : '',
    // default_method: '',
    // measurements: []
  });
  const [rows, setRows] = useState([
    { id: 1, menu_item_id: "", measurements: [], fg_consumption_per_person_kgs : "", fg_consumption_per_person_ltr : "", fg_consumption_per_person_nos : "", avg_density : ""},
  ]);
  const [rowCount, setRowCount] = useState(1);
  const [rowDisable, setRowDisable] = useState([]);
  const [validationErrors, setValidationErrors] = useState({
    date: false,
    session: false,
    // menu_category_id: false,
    menu_item_id: false,
    // default_method: ''
  });
  // const [menuitemCategory, setMenuitemCategory] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [menuitemList, setMenuitemList] = useState([]);
  const [methodList, setMethodList] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const formRef = useRef(null);

  const navigate = useNavigate();
  useEffect(() => {
    getSessionList();
    // getMenuitemCategory();
    getMenuItem();
    getMethodList();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (detail) {
        const formateDate = new Date(detail[0].date)
          .toISOString()
          .split("T")[0];
        const sessionId = detail[0].session;
        const sessionItem = sessionList.find((item) => item.name === sessionId);
        const sessionObject = sessionItem
          ? { value: sessionItem.id, label: sessionItem.name }
          : { value: "", label: "" };
        if (sessionItem) {
          try {
            const mealCondition = ` m.date='${formateDate}' AND m.session='${sessionItem.id}'`;
            const response = await axios.get(`${baseUrl}/meal_master/item_list?condition=${mealCondition}`);
            const list = response.data.data;
            const newArray = [];

          for (const [index, item] of list.entries()) {
            // const newArray = await Promise.all(list.map(async(item, index) => {
              const menuitem = menuitemList.find(
                (v) => v.id == item.menu_item_id
              );
              const menuitemObject = menuitem
                ? {
                    value: menuitem.id,
                    label: `${menuitem.item_name} (${menuitem.eng_name})`,
                  }
                : { value: "", label: "" };

              const measurementsArray = item.measurements?.split(",").map(Number) ?? [];
              let fg_consumption_per_person_kgs;
              let avg_density;
              let fg_consumption_per_person_ltr;
              if(!item.fg_consumption_per_person_kgs && (measurementsArray.includes(1) || measurementsArray.includes(4))){
                const condition = `menu_item_id = '${menuitemObject.value}' AND method = '1'`;
                const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
                fg_consumption_per_person_kgs = response.data.data?.[0]?.fg_consumption_per_person ?? '';
                avg_density = response.data.data?.[0]?.avg_density ?? '';
                // fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(2) : 0;
              }else{
                fg_consumption_per_person_kgs = item.fg_consumption_per_person_kgs;
                avg_density = item.avg_density;
                // fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(2) : 0;
              }

              // 
              if(!item.fg_consumption_per_person_ltr && (measurementsArray.includes(2) || measurementsArray.includes(5))){
                // console.log("rerer");
                // const condition = `menu_item_id = '${menuitemObject.value}' AND method = '2'`;
                // const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
                // fg_consumption_per_person_kgs = response.data.data?.[0]?.fg_consumption_per_person ?? '';
                // avg_density = response.data.data?.[0]?.avg_density ?? '';
                fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(3) : 0;
              }else{
                fg_consumption_per_person_ltr = item.fg_consumption_per_person_ltr;
                avg_density = item.avg_density;
              }

              let fg_consumption_per_person_nos;
              if(!item.fg_consumption_per_person_nos && (measurementsArray.includes(3) || measurementsArray.includes(6))){
                const condition = `menu_item_id = '${menuitemObject.value}' AND method = '3'`;
                const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
                fg_consumption_per_person_nos = response.data.data?.[0]?.fg_consumption_per_person ?? '';
                avg_density = response.data.data?.[0]?.avg_density ?? '';
              }else{
                fg_consumption_per_person_nos = item.fg_consumption_per_person_nos;
                avg_density = item.avg_density;
              }

              const condition = `menu_item_id = '${menuitemObject.value}' AND date = '${formateDate}' AND session = '${sessionItem.id}'`;
              const fgDetailResponse = await axios.get(`${baseUrl}/comman/list?table=finished_goods&condition=${condition}`);
              

              newArray.push({
                id: index + 1,
                menu_item_id: menuitemObject,
                measurements: measurementsArray,
                fg_consumption_per_person_kgs : fg_consumption_per_person_kgs,
                fg_consumption_per_person_ltr : fg_consumption_per_person_ltr,
                fg_consumption_per_person_nos : fg_consumption_per_person_nos,
                avg_density : avg_density,
                db_id : item.id,
                disable : fgDetailResponse.data.data.length > 0 ? true : false
              });
            };
            // console.log(newArray);
            setRows(newArray);
            setRowDisable(newArray.filter(item => item.disable).map(item => item.id));
            setRowCount(newArray.length);
            const newState = {
              currentDate: formateDate || today,
              date: formateDate || today,
              session: sessionObject || "",
            };

            setFormData(newState);
          } catch (error) {
            // Handle errors
            console.error("Error fetching meal items:", error);
          }
        }
      }
    };

    fetchData();
  }, [detail, sessionList, menuitemList]);

  const getSessionList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=session_master&condition=status=1`
    );
    setSessionList(response.data.data);
  };

  const getMenuItem = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=item_master&condition=status=1`
    );
    setMenuitemList(response.data.data);
  };

  const getMethodList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=method_master&condition=status=1`
    );
    setMethodList(response.data.data);
  };

  const handleRefresh = async (e, id) => {
    e.preventDefault();
    if(id){
      const newArray = await Promise.all(rows.map(async(item, index) => {
          if(item.menu_item_id.value === id){
            const measurementsArray = item.measurements ?? [];
              let fg_consumption_per_person_kgs;
              let avg_density;
              let fg_consumption_per_person_ltr;
              if((measurementsArray.includes(1) || measurementsArray.includes(4))){
                const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '1'`;
                const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
                fg_consumption_per_person_kgs = response.data.data?.[0]?.fg_consumption_per_person ?? '';
                avg_density = response.data.data?.[0]?.avg_density ?? '';
                // fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(2) : 0;
              }
        
              // 
              if((measurementsArray.includes(2) || measurementsArray.includes(5))){
                
                fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(3) : 0;
              }
        
              let fg_consumption_per_person_nos;
              if((measurementsArray.includes(3))){
                const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '3'`;
                const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
                fg_consumption_per_person_nos = response.data.data?.[0]?.fg_consumption_per_person ?? '';
                avg_density = response.data.data?.[0]?.avg_density ?? '';
              }
              if((measurementsArray.includes(6))){
                const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '6'`;
                const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
                fg_consumption_per_person_nos = response.data.data?.[0]?.fg_consumption_per_person ?? '';
                avg_density = response.data.data?.[0]?.avg_density ?? '';
              }
        
              return {
                ... item,
                fg_consumption_per_person_kgs : fg_consumption_per_person_kgs,
                fg_consumption_per_person_ltr : fg_consumption_per_person_ltr,
                fg_consumption_per_person_nos : fg_consumption_per_person_nos,
                avg_density : avg_density,
              };
          }else{
            return item;
          }
      }));
      setRows(newArray);
    }
    // const newArray = await Promise.all(rows.map(async(item, index) => {
    //   const measurementsArray = item.measurements ?? [];
    //   let fg_consumption_per_person_kgs;
    //   let avg_density;
    //   let fg_consumption_per_person_ltr;
    //   if((measurementsArray.includes(1) || measurementsArray.includes(4))){
    //     const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '1'`;
    //     const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
    //     fg_consumption_per_person_kgs = response.data.data?.[0]?.fg_consumption_per_person ?? '';
    //     avg_density = response.data.data?.[0]?.avg_density ?? '';
    //     // fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(2) : 0;
    //   }

    //   // 
    //   if((measurementsArray.includes(2) || measurementsArray.includes(5))){
        
    //     fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(3) : 0;
    //   }

    //   let fg_consumption_per_person_nos;
    //   if((measurementsArray.includes(3) || measurementsArray.includes(6))){
    //     const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '3'`;
    //     const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
    //     fg_consumption_per_person_nos = response.data.data?.[0]?.fg_consumption_per_person ?? '';
    //     avg_density = response.data.data?.[0]?.avg_density ?? '';
    //   }

    //   return {
    //     ... item,
    //     fg_consumption_per_person_kgs : fg_consumption_per_person_kgs,
    //     fg_consumption_per_person_ltr : fg_consumption_per_person_ltr,
    //     fg_consumption_per_person_nos : fg_consumption_per_person_nos,
    //     avg_density : avg_density,
    //   };
    // }));
    // setRows(newArray);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const gujaratiRegex = /^[\u0A80-\u0AFF\s]+$/;
    // const gujaratiRegex = /^[\u0A80-\u0AFF\s()+\-]*$/;
    const newValidationErrors = {
      currentDate: formData.date === "",
      date: formData.date === "",
      session: formData.session === "",
    };
    console.log('submitted form Data',formData)
    setValidationErrors(newValidationErrors);
    const hasErrors = Object.values(newValidationErrors).some((error) => error);
    if (hasErrors) {
      // Find the first invalid input element
      const firstInvalidInput = formRef.current.querySelector(".invalid");
      if (firstInvalidInput) {
        // Scroll to the first invalid input element
        firstInvalidInput.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    } else {
      console.log(rows);
      let url;
      try {
        setPopupOpen(true);
        if (detail) {
          url = `meal_master/edit/${detail[0].id}`;
        } else {
          url = "meal_master/add";
        }
        await axios.post(`${baseUrl}/${url}`, {
          formData: formData,
          rows: rows,
        });
        setPopupOpen(false);
        navigate("/meal_master/list");
      } catch (error) {
        setPopupOpen(false);
        console.log(error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleSelectChange = (data, option) => {
    setFormData((prevData) => ({
      ...prevData,
      [option.name]: data,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [option.name]: false,
    }));
  };

  const handleMenuitemChange = (id, data) => {
    const checkMenuItem = rows.find(
      (item) => item.menu_item_id.value == data.value
    )
      ? true
      : false;

    if (!checkMenuItem) {
      const updatedRows = rows.map((row) =>
        row.id === id ? { ...row, menu_item_id: data } : row
      );
      setRows(updatedRows);
      if(updatedRows.find((item) => item.id === id)){
        callApi(updatedRows.find((item) => item.id === id));
      }
    } else {
      alert("menu item allready exist");
      return;
    }
  };

  const handleCheckboxChange = (id, methodId, isChecked) => {
    setRows((prevRows) => {
      return prevRows.map((row) => {
        if (row.id === id) {
          const updatedMeasurements = isChecked
            ? [...row.measurements, methodId]
            : row.measurements.filter(
                (measurementId) => measurementId !== methodId
              );
              callApi( { ...row, measurements: updatedMeasurements });
          return { ...row, measurements: updatedMeasurements };
        }
        
        return row;
      });
    });
    
  };

  const callApi = async(row) => {
    if(row.menu_item_id.value && row.measurements){
      
      if ((row.measurements.includes(1) || row.measurements.includes(4)) && row.fg_consumption_per_person_kgs == '') {
        const condition = `menu_item_id = '${row.menu_item_id.value}' AND method = '1'`;
        const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
        const value = response.data.data?.[0]?.fg_consumption_per_person ?? '';
        const avg_density = response.data.data?.[0]?.avg_density ?? '';
        const fg_consumption_per_person_ltr = avg_density > 0 ? (value / avg_density).toFixed(2) : 0;
       
        setRows((prevRows) =>
          prevRows.map((r) =>
            r.id === row.id ? { ...r, ["fg_consumption_per_person_kgs"]: value, ["avg_density" ]: avg_density, ["fg_consumption_per_person_ltr"] : fg_consumption_per_person_ltr } : r
          )
        );
      }
      // if ((row.measurements.includes(2) || row.measurements.includes(5)) && row.fg_consumption_per_person_ltr == '') {
      //   const condition = `menu_item_id = '${row.menu_item_id.value}' AND method = '2'`;
      //   const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
      //   const value = response.data.data?.[0]?.fg_consumption_per_person ?? '';
      //   const avg_density = response.data.data?.[0]?.avg_density ?? '';

      //   setRows((prevRows) =>
      //     prevRows.map((r) =>
      //       r.id === row.id ? { ...r, ["fg_consumption_per_person_ltr"]: value, ["avg_density" ]: avg_density } : r
      //     )
      //   );
        
      // }
      if ((row.measurements.includes(3) || row.measurements.includes(6)) && row.fg_consumption_per_person_nos == '') {
        const condition = `menu_item_id = '${row.menu_item_id.value}' AND method = '3'`;
        const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
        const value = response.data.data?.[0]?.fg_consumption_per_person ?? '';
        const avg_density = response.data.data?.[0]?.avg_density ?? '';

        setRows((prevRows) =>
          prevRows.map((r) =>
            r.id === row.id ? { ...r, ["fg_consumption_per_person_nos"]: value, ["avg_density" ]: avg_density } : r
          )
        );
        
      }
      
     
    }
   
  }
  const handleFgChange = (e, id) => {
    const { value, name } = e.target;
    

    setRows((prevRows) =>
      prevRows.map((row) =>{
        if(row.id === id) {
          const updatedRow = { ...row, [name]: value };
          if (name === 'fg_consumption_per_person_kgs') {
            const fg_consumption_per_person_kgs = updatedRow.fg_consumption_per_person_kgs || 0;
            const avg_density = updatedRow.avg_density; // Avoid division by zero
            updatedRow.fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(3) : 0;
          }else if(name === 'fg_consumption_per_person_ltr'){
            const fg_consumption_per_person_ltr = updatedRow.fg_consumption_per_person_ltr || 0;
            const avg_density = updatedRow.avg_density;
            updatedRow.fg_consumption_per_person_kgs = avg_density > 0 ? ( fg_consumption_per_person_ltr * avg_density).toFixed(3) : 0;
          }else if(name === 'avg_density'){
            const fg_consumption_per_person_ltr = updatedRow.fg_consumption_per_person_ltr || 0;
            const avg_density = updatedRow.avg_density;
            updatedRow.fg_consumption_per_person_kgs = avg_density > 0 ? ( fg_consumption_per_person_ltr * avg_density).toFixed(3) : 0;

          }
          console.log(updatedRow);
          return updatedRow;
        }else{
          return row;
        } 
      }
      )
    );
  };
  const handleAddRow = (e) => {
    e.preventDefault();
    setRowCount(rowCount + 1);
    setRows([
      ...rows,
      { id: rowCount + 1, menu_item_id: "", measurements: [], fg_consumption_per_person_kgs: '', fg_consumption_per_person_ltr : '', fg_consumption_per_person_nos : '', avg_density : '' },
    ]);
  };

  const handleRemoveRow = async(id) => {
    setRows(rows.filter((row) => row.id !== id));
    // console.log(rows.filter((row) => row.id == id))
    const db_id = rows.filter((row) => row.id === id)?.[0].db_id;
    if(db_id){
      try {
        // setPopupOpen(true);
  
        const response = await axios.post(`${baseUrl}/meal_master/status/${db_id}`);
        toast.success("Data successfully saved!");
      } catch (error) {
        toast.error("Failed to submit the form.");
        // setPopupOpen(false);
        console.log(error);
      }
    }
  };

  const isRowValid = (row) => {
    if (row.menu_item_id !== "") {
      return true;
    }
    return false;
  };
  const isAddRowDisabled = () => {
    return rows.some((row) => !isRowValid(row));
  };

  return (
    <form ref={formRef}>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="space-y-12">
        <div>
          <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
            <div className="col-span-1 lg:col-span-2">
              <label
                htmlFor="date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Date
              </label>
              <div className="mt-2">
                <input
                  type="date"
                  name="date"
                  id="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  className={`block w-full rounded-md border ${
                    validationErrors.date ? "border-red-500" : "border-gray-300"
                  } py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                />
              </div>
              {validationErrors.date && (
                <div className="text-red-500">Please enter date.</div>
              )}
            </div>
            <div className="col-span-1 lg:col-span-2">
              <label
                htmlFor="session"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Session
              </label>
              <div className="mt-2">
                <Select
                  classNamePrefix="select"
                  value={formData.session}
                  name="session"
                  onChange={(selectedOption, name) => {
                    handleSelectChange(selectedOption, name);
                  }}
                  options={sessionList.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  filterOption={(option, inputValue) => {
                    return option.data.label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase());
                  }}
                />
              </div>
              {validationErrors.session && (
                <div className="text-red-500">Please select a session.</div>
              )}
            </div>
            <div className="col-span-1 sm:col-span-1">
            
              {/* <div className="mt-7">
                  <button
                    onClick={handleAddRow}
                    disabled={isAddRowDisabled()}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Add Row
                  </button>
                  </div> */}
              </div>
              <div className="col-span-1 sm:col-span-1">
            
              {/* <div className="mt-7">
                  <button
                    onClick={handleRefresh}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Refresh
                  </button>
                  </div> */}
              </div>
          </div>

          
          {rows.map((row) => (
            <div
              className="mt-10 grid grid-cols-1 sm:grid-cols-4 gap-x-5 gap-y-8 border-b-4 border-blue-500" 
              key={row.id}
            >
              <div className="col-span-1 sm:col-span-1">
                <label
                  htmlFor="menu_item_id"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Menuitem
                </label>
                <div className="mt-2">
                  <Select
                    classNamePrefix="select"
                    value={row.menu_item_id}
                    name="menu_item_id"
                    onChange={(selectedOption) => {
                      handleMenuitemChange(row.id, selectedOption);
                     
                    }}
                    options={menuitemList.map((item) => ({
                      value: item.id,
                      label: `${item.item_name} (${item.eng_name})`,
                    }))}

                    isDisabled={rowDisable.includes(row.id)}
                    filterOption={(option, inputValue) => {
                      return option.data.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase());
                    }}
                  />
                </div>
              </div>
              <div className="col-span-1 sm:col-span-2">
                <label
                  htmlFor="measurement"
                  className="block lg:text-sm font-medium leading-6 sm:text-xs text-gray-900"
                >
                  Measurement
                </label>
                <div className="grid grid-cols-3">
                  {methodList.map((method, index) => (
                    <div key={index}>
                      <label>
                        <input
                          type="checkbox"
                          name={method.name}
                          className="mr-2 "
                          disabled={rowDisable.includes(row.id)}
                          checked={row.measurements?.includes(method.id)}
                          onChange={(e) =>
                            {handleCheckboxChange(
                              row.id,
                              method.id,
                              e.target.checked
                            );}
                          }
                        />
                        {method.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-span-1 sm:col-span-1">
                
                 <button
                    onClick={handleAddRow}
                    disabled={isAddRowDisabled()}
                    className="bg-blue-500 mr-5 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Add Row
                  </button>
                <button
                    onClick={(e) => handleRefresh(e, row.menu_item_id.value)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-5"
                  >
                    Refresh
                  </button>
                {row.id !== 1 && (
                  <button
                    onClick={() => handleRemoveRow(row.id)}
                    disabled={rowDisable.includes(row.id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Remove
                  </button>
                )}
              </div>
              {(row.measurements?.includes(1) || row.measurements?.includes(4)) && <div className="col-span-1 sm:col-span-1 mb-10">
                <label htmlFor="fg_consumption_per_person_kgs" className="block text-sm font-medium leading-6 text-gray-900">Fg Consumption per person (kgs)</label>
                  <div className="mt-2">
                    <input
                      id="fg_consumption_per_person_kgs"
                      name="fg_consumption_per_person_kgs"
                      type="number" 
                      step="0.01"
                      onWheel={(e) => e.target.blur()}
                      placeholder='Fg Consumption per person (kgs)'
                      value={row.fg_consumption_per_person_kgs}
                      onChange={(e) => {handleFgChange(e, row.id)} }
                      className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    />
                  </div>
              </div>}
              {(row.measurements?.includes(2) || row.measurements?.includes(5)) && <div className="col-span-1 sm:col-span-1 mb-10">
                <label htmlFor="fg_consumption_per_person_ltr" className="block text-sm font-medium leading-6 text-gray-900">Fg Consumption per person (Ltr)</label>
                  <div className="mt-2">
                    <input
                      id="fg_consumption_per_person_ltr"
                      name="fg_consumption_per_person_ltr"
                      type="number" 
                      step="0.01"
                      placeholder='Fg Consumption per person (ltr)'
                      value={row.fg_consumption_per_person_ltr}
                      onChange={(e) => handleFgChange(e, row.id)}
                      onWheel={(e) => e.target.blur()}
                      className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    />
                  </div>
              </div>}
              {(row.measurements?.includes(3) || row.measurements?.includes(6)) && <div className="col-span-1 sm:col-span-1 mb-10">
                <label htmlFor="fg_consumption_per_person_nos" className="block text-sm font-medium leading-6 text-gray-900">Fg Consumption per person (nos)</label>
                  <div className="mt-2">
                    <input
                      id="fg_consumption_per_person_nos"
                      name="fg_consumption_per_person_nos"
                      type="number" 
                      step="0.01"
                      placeholder='Fg Consumption per person (nos)'
                      value={row.fg_consumption_per_person_nos}
                      onChange={(e) => {handleFgChange(e, row.id)} }
                      onWheel={(e) => e.target.blur()}
                      className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    />
                  </div>
              </div>}
              <div className="col-span-1 sm:col-span-1 mb-10">
                <label htmlFor="avg_density" className="block text-sm font-medium leading-6 text-gray-900">Avg Density</label>
                  <div className="mt-2">
                    <input
                      id="avg_density"
                      name="avg_density"
                      type="number" 
                      step="0.01"
                      placeholder='Avg Density'
                      value={row.avg_density}
                      onChange={(e) => {handleFgChange(e, row.id)} }
                      onWheel={(e) => e.target.blur()}
                      className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    />
                  </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <Link to="/meal_master/list">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
        </Link>
        <button
          onClick={handleSubmit}
          type="submit"
          className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}
