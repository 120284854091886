import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl, getDateWithDay, requirementStatusList } from "../../comman/config";
import { VegRequirementFilter } from "../../components/Filter/VegRequirementFilter.js";
import toast, { Toaster } from "react-hot-toast";
import RequirementModel from "../../components/RequirementModel.js";
import Loader from "../../components/Loader.js";
import NoRecordsFound from "../../components/NoRecordsFound.js";
// import Pagination from "react-js-pagination";

function RequirementList() {
  const today = new Date().toISOString().split("T")[0];
  const [requirementList, setRequirementList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [session, setSession] = useState({});
  const [requirementStatus, setRequirementStatus] = useState({});
  const [item, setItem] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemIngedientList, setItemIngredientList] = useState(null);
  const [methodList, setMethodList] = useState([]);
  const [lockScreen, setLockScreen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  useEffect(() => {
    getMethodList()
  }, []);
useEffect(() => {
  getRequirementList();
}, [fromDate, toDate, session, requirementStatus, item])

  const handelFilterChange = async (from_date, to_date, session, item, requirement_status) => {
    
        setFromDate(from_date);
        setToDate(to_date);
        setSession(session);
        setItem(item);
        setRequirementStatus(requirement_status);

        
  };

  const getMethodList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=method_master&condition=status=1`
    );
    setMethodList(response.data.data);
  };
  const getRequirementList = async() => {
    console.log(requirementStatus);
    setLoading(true)
    setNoRecordsFound(false)
    const response = await axios.post(`${baseUrl}/requirement/list`,
      { from_date : fromDate,
        to_date :toDate,
        session :session?.value,
        item : item?.value,
        requirement_status: requirementStatus?.value
       
    });
    const reqList = response.data.data;
    const updatedReqList = [];
    for (let item of reqList) {
      const condition = `pr.menu_item_id='${item.menu_item_id}' AND pr.date='${item.date}' AND pr.session='${item.session}' AND pr.batch='${item.batch}' AND im.store=3`;
      const RecipeDbData = await axios.get(`${baseUrl}/requirement/ingredient_list?condition=${condition}`);
      const ingredientsList = RecipeDbData.data.data;
    
      // Add item to updatedReqList only if ingredientsList is not empty
      if (ingredientsList && ingredientsList.length > 0) {
        updatedReqList.push(item);
      }
    }
   
    setRequirementList(updatedReqList);
    setNoRecordsFound(updatedReqList.length === 0);
    setLoading(false)
  }

  
  const handleSubmit = async() => {
   
    const response = await axios.post(`${baseUrl}/requirement/status_update`,
      { selectedRows : selectedRows,
        status: 2});

      toast.success("Data successfully saved!");
      getRequirementList();
  };

  const handleToggleSelect = (index) => {
    const updatedSelectedRows = selectedRows.includes(requirementList[index])
        ? selectedRows.filter(item => item !== requirementList[index]) // Deselect
        : [...selectedRows, requirementList[index]]; // Select

   setSelectedRows(updatedSelectedRows);
    

};
const handleSwitchSelectAll = () => {
  if (selectAll) {
      setSelectedRows([]);
  } else {
      setSelectedRows(requirementList.filter((item) => item.requirement_status == 1)); // Select all rows
  }
  setSelectAll(!selectAll);
};
  const displayIngredient = async(e, item) => {
    e.preventDefault();
    setLoading(true)
    const condition = `pr.menu_item_id='${item.menu_item_id}' AND pr.date = '${item.date}' AND pr.session='${item.session}' AND pr.batch = '${item.batch}' AND im.store = 3`;
    const frezzCondition = `pr.date = '${item.date}' AND pr.session = '${item.session}' AND pr.menu_item_id = '${item.menu_item_id}' AND pr.batch = '${item.batch}' AND imm.requirement_status = 2 and imm.ingredient_added = 2 AND irm.order_status = 3 group by pr.ingredient_id`;
    
    // const condition = `pr.menu_item_id='${item.menu_item_id}' AND pr.date = '${item.date}' AND pr.session='${item.session}' AND pr.batch = '${item.batch}'`;
    const RecipeDbData = await axios.get(`${baseUrl}/requirement/ingredient_list?condition=${condition}&frezzCondition=${frezzCondition}`);
    
    const ingredientsList = RecipeDbData.data.data;
    setLockScreen(RecipeDbData.data.frezzResult)
   
    if(ingredientsList.length > 0){
      const updatedIngredientsList = [];
      for (const i of ingredientsList) {
      
          const condition = `date = '${item.date}' AND session = '${item.session}' AND menu_item_id = '${item.menu_item_id}' AND batch = '${item.batch}' AND ingredient_id = '${i.ingredient_id}'`;
          const response = await axios.get( `${baseUrl}/comman/list?table=item_requirement_detail&condition=${condition}`);
          
          
          let wastage;
          let gross_qty;
          if(response.data.data.length > 0){
            wastage = response.data.data[0].wastage ?? 0;
            gross_qty = response.data.data[0].gross_required_qty ?? 0;
          }else{
            wastage = parseFloat(i.wastage ?? 0);
            gross_qty = (parseFloat(i.required_qty) + (parseFloat(i.required_qty) * (wastage / 100))).toFixed(2);
          }
          const method = methodList.find((idx) => idx.id === i?.unit);
          
          const unitname = method?.name ?? "-";
          updatedIngredientsList.push({
            ...i,
            wastage: wastage,
            unitname: unitname,
            gross_required_qty: gross_qty
        });
        };
      setSelectedItem(item);
      setItemIngredientList(updatedIngredientsList);
      
    }
    setLoading(false)
    document.body.style.overflowY = 'hidden';
    setShowModal(true);
  }

  const clearItem = () => {
    setSelectedItem(null);
    setItemIngredientList(null);
    getRequirementList();
    document.body.style.overflowY = 'scroll';
  };

  return (
    <div className="p-4">
      {loading && <Loader/>}
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
         Requirement
        </h1>
        
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <VegRequirementFilter handleFilterChange={handelFilterChange} page="requirement" />
        
       
       
      </div>
      {noRecordsFound && <NoRecordsFound/>}
      {requirementList.length > 0 && <div className="overflow-x-auto">
          <table className="w-full mt-10 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {/* <th scope="col" className="px-2 py-1 border border-black text-center "><input type="checkbox" className="newrow checkboxAll"  onChange={handleSwitchSelectAll} checked={selectAll} /></th> */}
                <th scope="col" className="px-2 py-1 border border-black text-center ">Date</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Session</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Menu Item</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Kit No</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Submitted for Order</th>
              </tr>
            </thead>
            <tbody>
              {requirementList.map((item, index) => (
                <tr key={index} className="border border-black-b">
                  {/* {item.requirement_status == 1 && <td className="px-2 py-1 border border-black text-center"><input type="checkbox" checked={selectedRows.includes(item)} onChange={() => handleToggleSelect(index)} /></td>}
                  {item.requirement_status == 2 && <td className="px-2 py-1 border border-black text-center"></td>} */}
                  <td className="px-2 py-1 border border-black text-center">{getDateWithDay(item.date)}</td>
                  <td className="px-2 py-1 border border-black text-center">{item.name}</td>
                  <td className="px-2 py-1 border border-black text-center">{item.eng_name}</td>
                  <td className="px-2 py-1 border border-black text-center">{item.requirement_status == 2 ? <a href="#" className="underline text-blue-500" onClick={(e) => displayIngredient(e, item)}>{item.kit_no}</a> : item.kit_no}</td>
                  <td className="px-2 py-1 border border-black text-center">
                    {item.requirement_status == "1" ? "Pending Requirement" : (item.requirement_status == "2" ? (item.ingredient_added == 2 ? "Submit for order" : "Pending Requirement") : "Pending Requirement")}
                   
                    
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          
        </div>}
        <RequirementModel showModal={showModal} setShowModal={setShowModal}  item={selectedItem} ingredientsList={itemIngedientList} lockScreen={lockScreen} clearItem={clearItem} />
        <div className='m-5'>
        {/* <button
                    onClick={handleSubmit}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                    Submit
                </button> */}
                </div>
    </div>
  );
}

export default RequirementList;
