import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { VegRequirementFilter } from '../../components/Filter/VegRequirementFilter';
import IngredientBatchModel from '../../components/IngredientBatchModel';
import axios from 'axios';
import { baseUrl } from '../../comman/config';
import Select from 'react-select';
import Loader from "../../components/Loader.js";
import NoRecordsFound from "../../components/NoRecordsFound.js";


function ReceiptList() {
  const today = new Date().toISOString().split("T")[0];
  const [ingredientReceiptList, setIngredientReceiptList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [receiptStatus, setReceiptStatus] = useState({});
  const [ingredient, setIngredient] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [itemOrderList, setItemOrderList] = useState(null);
  const [orderIngedientList, setOrderIngredientList] = useState(null);
  const [ingredientList, setIngredientList] = useState([]);
  const [loading, setLoading] = useState(false)
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  
  useEffect(() => {
    getIngredientReceiptList();
  }, [fromDate, toDate, ingredient, receiptStatus])

  useEffect(()=>{
    console.log(ingredientReceiptList)
    getIngredientList()
  },[ingredientReceiptList])

  const handelFilterChange = async (from_date, to_date, session, item, requirement_status, order_status, order_id, purchase_status, receipt_status, issue_status, ingredient) => {
    setFromDate(from_date);
    setToDate(to_date);
    setIngredient(ingredient); 
    setReceiptStatus(receipt_status);
  };

  const getIngredientList = async () => {
    const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`);
    const existingIngredientIds = ingredientReceiptList.map(item => item.ingredient_id);
    const list = response.data.data.filter((item) => item.status == 1 && item?.store?.id == 3 && !existingIngredientIds.includes(item.id));
    setIngredientList(list);
  };

  const getIngredientReceiptList = async() => {
    setLoading(true)
    setNoRecordsFound(false)
    const response = await axios.post(`${baseUrl}/receipt/list`,
      { from_date : fromDate,
        to_date :toDate,
        ingredient : ingredient?.value,
        receipt_status : receiptStatus?.value
      
    });
    const updatedArray = response?.data?.data.map(item => ({
      ...item,         // Spread existing properties
      purchase_qty: item.purchase_qty ?? "", 
      pur_pack_nos: item?.pur_pack_nos ?? 0,         // Add new key-value pair
      received_qty: item.received_qty ?? "",
      rec_pack_nos: item?.rec_pack_nos ?? 0,
      diff_qty : item.received_qty - item.purchase_qty ?? 0,
      diff_pack : item.rec_pack_nos - item.pur_pack_nos ?? 0,
      add_received:false,
  }));
    setIngredientReceiptList(updatedArray);
    setNoRecordsFound(updatedArray.length === 0);
    setLoading(false)
  }

  const handleToggleSelect = (index) => {
    const updatedSelectedRows = selectedRows.includes(ingredientReceiptList[index])
        ? selectedRows.filter(item => item !== ingredientReceiptList[index]) // Deselect
        : [...selectedRows, ingredientReceiptList[index]]; // Select

   setSelectedRows(updatedSelectedRows);
  };

  const handleSwitchSelectAll = () => {
    if (selectAll) {
        setSelectedRows([]);
    } else {
        setSelectedRows(ingredientReceiptList.filter((item) => item.receipt_status == 1)); // Select all rows
    }
    setSelectAll(!selectAll);
  };

  const displayIngredientDetail = async(e, item) => {
    e.preventDefault();
    setLoading(true)
    const condition = `ingredient_id = '${item.ingredient_id}' AND order_status = 3 AND purchase_status = 3`;
    const response = await axios.get(
        `${baseUrl}/comman/list?table=item_requirement_summary&condition=${condition}`
    );
    const orderList = response.data.data;
    setItemOrderList(orderList);
    setLoading(false)
    setShowModal(true);
  }

  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;
    
    setIngredientReceiptList((prevFormData) => {
      if(name === 'rec_pack_nos'){
        const updatedFormData = [...prevFormData];
        const pur_pack_nos = updatedFormData[index].pur_pack_nos;
        const diff_pack = (value - pur_pack_nos).toFixed(2);
        updatedFormData[index] = { ...updatedFormData[index], [name]: value, diff_pack : diff_pack };
        return updatedFormData;
      }else{
        const updatedFormData = [...prevFormData];
        const purchase_qty = updatedFormData[index].purchase_qty;
        const diff_qty = (value - purchase_qty).toFixed(2);
        updatedFormData[index] = { ...updatedFormData[index], [name]: value, diff_qty : diff_qty };
        return updatedFormData;
      }
    });
  };

  const handleAddButtonClick = (index) => {
    setIngredientReceiptList((prevFormData)=>{
      const updatedFormData = [...prevFormData]
        updatedFormData[index] = {...updatedFormData[index], add_received: true}
        console.log(updatedFormData[index])
        return updatedFormData
    })
  }

  const clearItem = () => {
    setSelectedRows([]);
    setItemOrderList(null);
    setOrderIngredientList(null);
    getIngredientReceiptList();
  };

  const handleSubmit = async() => {
    if(selectedRows.length == 0){
      alert("Please select at least one row");
      setSelectedRows([]);
      return;
    }
    console.log(selectedRows);
    if(selectedRows.some(item => item.received_qty === "")){
      alert("One or more items have an empty recieved qty value.");
      setSelectedRows([]);
      return;
    }
    if(selectedRows.some(item => item.add_received_qty === "")){
      alert("One or more items have an empty additional recieved qty value.");
      setSelectedRows([]);
      return;
    }
    if(selectedRows.some(item => item.ingredient_id === null)){
      alert("One or more items have an empty ingredient value.");
      setSelectedRows([])
      return
    }
    if(selectedRows.length > 0){
        try{
            const response = await axios.post(`${baseUrl}/receipt/ingredient_purchase`,
              { ingredientsList : selectedRows
                });
                const msg = response.data.msg;
                toast.success(msg);
                getIngredientReceiptList();
          }catch (error) {
            toast.error("Failed to add item. Please try again.");
          }
    }
    setSelectedRows([])
  };

  const handleSave = async() => {
    if(selectedRows.length == 0){
      alert("Please select at least one row");
      setSelectedRows([]);
      return;
    }
    console.log(selectedRows);
    if(selectedRows.some(item => item.received_qty === "")){
      alert("One or more items have an empty recieved qty value.");
      setSelectedRows([]);
      return;
    }
    if(selectedRows.some(item => item.ingredient_id === null)){
      alert("One or more items have an empty ingredient value.");
      setSelectedRows([])
      return
    }
    // const list = ingredientReceiptList.filter((item) => (item.received_qty !== "" && (item.receipt_status === 1 || item.receipt_status === 2 ) && item.ingredient_id !== null));
    if(selectedRows.length > 0){
      try{
        const response = await axios.post(`${baseUrl}/receipt/ingredient_save`,
          { 
            ingredientsList : selectedRows
          });
          toast.success("Data save sucessfully.");
          getIngredientReceiptList();
      }catch (error) {
        toast.error("Failed to add item. Please try again.");
      }
    }
    setSelectedRows([])
  }

  const handleAddNewIngredientRow = () => {
    setIngredientReceiptList([...ingredientReceiptList, {
      ingredient: '',
      ingredient_obj:{},
      ingredient_id: null,
      total_order: 0,
      purchase_qty: 0,
      pur_pack_nos: 0,
      received_qty: '',
      rec_pack_nos: '',
      diff_qty: 0,
      purchase_status: 3,
      receipt_status:1,
      isNew: true
    }]);
  };

  const handleNewIngredientChange = (selectedOptions,index) => {
    // setNewIngredient((prev)=>({...prev,ingredient:ingredient}))
    console.log(selectedOptions)
    const updatedList =   [...ingredientReceiptList]
    updatedList[index].ingredient_obj = selectedOptions
    updatedList[index].ingredient = selectedOptions.ingredient
    updatedList[index].ingredient_id = selectedOptions.value
    setIngredientReceiptList(updatedList)
  }

  return (
    <div className="p-4">
        {loading && <Loader/>}
        <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
        Receipt
        </h1>
        
        <Toaster position="top-center" reverseOrder={false} />
        </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <VegRequirementFilter handleFilterChange={handelFilterChange} page="receipt" />
      </div>
      {noRecordsFound && <NoRecordsFound/>}
      {ingredientReceiptList.length > 0 && <div className="overflow-x-auto">
          <table className="w-full mt-10 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-2 py-1 border border-black text-center "><input type="checkbox" className="newrow checkboxAll"  onChange={handleSwitchSelectAll} checked={selectAll} /></th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Ingredient</th>
                {/* <th scope="col" className="px-2 py-1 border border-black text-center ">Order Qty</th> */}
                <th scope="col" className="px-2 py-1 border border-black text-center" colSpan='2'>Purchased</th>
                {/* <th scope="col" className="px-2 py-1 border border-black text-center ">Pur Pack Nos.</th> */}
                <th scope="col" className="px-2 py-1 border border-black text-center" colSpan='2'>Recieved</th>
                {/* <th scope="col" className="px-2 py-1 border border-black text-center ">Rec Pack Nos.</th> */}
                <th scope="col" className="px-2 py-1 border border-black text-center ">Diff Qty</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Diff Pack</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Receipt Status</th>
              </tr>
              <tr>
                <th scope="col" className="px-2 py-1 border border-black text-center "></th>
                <th scope="col" className="px-2 py-1 border border-black text-center "></th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Qty</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Pack Nos.</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Qty</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Pack Nos.</th>
                <th scope="col" className="px-2 py-1 border border-black text-center "></th>
                <th scope="col" className="px-2 py-1 border border-black text-center "></th>
                <th scope="col" className="px-2 py-1 border border-black text-center "></th>
              </tr>
            </thead>
            <tbody>
              {ingredientReceiptList.map((item, index) => (
                <tr key={index} className="border border-black-b">
                  <td className="px-2 py-1 border border-black text-center"><input type="checkbox" checked={selectedRows.includes(item)} onChange={() => handleToggleSelect(index)} /></td>
                  {/* {item.receipt_status == 3 && <td className="px-2 py-1 border border-black text-center"></td>} */}
                  <td className="px-2 py-1 border border-black text-center">
                    {item.isNew ? (
                      <Select
                      // isMulti
                      classNamePrefix="select"
                      className='basic-multi-select'
                      value={item.ingredient_obj}
                      name="ingredient"
                      onChange={(selectedOptions) => handleNewIngredientChange(selectedOptions,index)}
                      options={ingredientList.map((item) => ({
                          value: item.id,
                          label: `${item.ingredient} (${item.eng_name})`,
                          unit:item.unit?.id,
                          ingredient: `${item.ingredient}`,
                          eng_name: item.eng_name,
                        }))}
                      placeholder="Ingredient List"
                      filterOption={(option, inputValue) => {
                          return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                      }}
                  />
                    ): (item.ingredient)}
                  </td>
                  {/* <td className="px-2 py-1 border border-black text-center">{item.total_order}</td> */}
                  <td className="px-2 py-1 border border-black text-center"><a href="#" className="underline text-blue-500" onClick={(e) => displayIngredientDetail(e, item)}>{item.purchase_qty}</a></td>
                  <td className="px-2 py-1 border border-black text-center">{item.pur_pack_nos}</td>
                  <td className="px-0 py-1 border border-black text-center">
                    <input type="number" 
                              id="received_qty" 
                              name="received_qty" 
                              disabled={item.receipt_status == 3}
                              value={item?.received_qty ?? ''} 
                              onWheel={(e) => e.target.blur()}
                              onChange={handleInputChange(index)}
                              onKeyDown={(e) => {
                                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                  e.preventDefault(); // Disable up and down arrow keys
                                }
                              }} 
                              className="px-2 py-1 border rounded-md w-20 bg-orange-50"
                            />
                          {item.receipt_status === 3 && 
                          <>
                            <button onClick={()=>{handleAddButtonClick(index)}} className='px-1 rounded  border mx-2  scale-150 hover:bg-gray-300'>&#x2b;</button>
                            {item.add_received && <input type="number" 
                              id="add_received_qty" 
                              name="add_received_qty" 
                              value={item?.add_received_qty ?? ''} 
                              onWheel={(e) => e.target.blur()}
                              onChange={handleInputChange(index)}
                              onKeyDown={(e) => {
                                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                  e.preventDefault(); // Disable up and down arrow keys
                                }
                              }}                               className="px-2 py-1 border rounded-md w-20 bg-orange-50"
                            />}
                          </>}</td>
                  <td className="px-2 py-1 border border-black text-center">
                    <input type="number" 
                      id="rec_pack_nos" 
                      name="rec_pack_nos" 
                      disabled={item.receipt_status == 3}
                      value={item?.rec_pack_nos ?? 0} 
                      onWheel={(e) => e.target.blur()}
                      onChange={handleInputChange(index)}
                      onKeyDown={(e) => {
                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                          e.preventDefault(); // Disable up and down arrow keys
                        }
                      }}                       className="px-2 py-1 border rounded-md w-20 bg-orange-50"/>
                  </td>
                  <td  className={`px-2 py-1 border border-black text-center ${item.diff_qty < 0 ? 'bg-red-500 text-black' : ''}`}>{item.diff_qty}</td>
                  <td  className={`px-2 py-1 border border-black text-center ${item.diff_pack < 0 ? 'bg-red-500 text-black' : ''}`}>{item.diff_pack}</td>
                  <td className="px-2 py-1 border border-black text-center">
                   {item.receipt_status == 1 ? "Pending Receipt" : (item.receipt_status == 2 ? "Pending Receipt" : "Received")}
                  </td>
                  
                </tr>
              ))}
            </tbody>
          </table>
          <IngredientBatchModel showModal={showModal} setShowModal={setShowModal} detailList={itemOrderList} clearItem={clearItem} type={3} />
        <div className='m-5'>
        
        <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
        >
          Save
        </button>
        <button
            onClick={handleAddNewIngredientRow}
            className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
        >
          Add Ingredient
        </button>
        <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-rose-500 text-white rounded-md mr-4 hover:bg-rose-600 focus:outline-none focus:ring focus:ring-rose-200"
        >
            Received
        </button>
        </div>
        </div>}
    </div>)
}

export default ReceiptList;