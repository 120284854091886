import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl, limit } from "../comman/config";
import Select from "react-select";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { useNavigate, Link } from "react-router-dom";
import { DateSessionFilter } from "../components/Filter/DateSessionFilter";
import toast, { Toaster } from "react-hot-toast";
// import Pagination from "react-js-pagination";

function RawMaterial() {
  const today = new Date().toISOString().split("T")[0];
  const [mealList, setMealList] = useState([]);
  const [methodList, setMethodList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [date, setDate] = useState(today);
  const [session, setSession] = useState("");
  const [formData, setFormData] = useState([]);
  const [detail, setDetail] = useState([]);
  const [transformedData, setTransformedData] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [validationAllErrors, setValidationAllErrors] = useState([]);
  const [mealItemArray, setMealItemArray] = useState([]);
  const role = localStorage.getItem('userRole');

  useEffect(() => {
    // Create initial formData and validationErrors based on mealList length
    const initialFormData = mealList.map(() => ({
      default_method: "",
      estimated_count: "",
      rm_to_fg_ratio: "",
      ras_qty_per_100_person: "",
      std_ras_qty_per_100_person:"",
      estimated_finished_goods: "",
      estimated_rm_required: "",
      fg_consumption_per_person: "",
      planned_rm_qty: "",
      estimated_production: "",
    }));
    setFormData(initialFormData);

    const initialValidationErrors = mealList.map(() => ({
      estimated_count: false,
      planned_rm_qty: false,
      estimated_production: false,
    }));
    setValidationErrors(initialValidationErrors);
  }, [mealList]);
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (mealList.length > 0 && activeIndex != null) {
      getMethodList();
    }
  }, [mealList, activeIndex]);

  useEffect(() => {
    if (mealList.length > 0 && methodList.length > 0 && activeIndex != null) {
      const itemId =
        mealList.find((item, index) => index === activeIndex).menu_item_id ??
        null;
      const fetchDetail = async () => {
        const condition = `menu_item_id='${itemId}' AND date = '${date}' AND session='${session.value}'`;
        const response = await axios.get(
          `${baseUrl}/comman/list?table=raw_material&condition=${condition}`,
          { headers: { 'Cache-Control': 'no-cache' } }
        );
        setDetail(response.data.data);
        const apiDetail = response.data.data[0];
        getDetailDispaly(apiDetail, itemId);
      };
      fetchDetail();
    }
  }, [mealList, methodList, activeIndex]);

  const getDetailDispaly = async (detail, itemId) => {
    try {
      if (detail) {
        const methodItem = methodList.find(
          (item) => item.id == detail.default_method
        );
        const methodObject = methodItem
          ? { value: methodItem.id, label: methodItem.name }
          : { value: "", label: "" };
        const newState = {
          default_method: methodObject,
          estimated_count: detail?.estimated_count || 0,
          rm_to_fg_ratio: detail?.rm_to_fg_ratio || 0,
          ras_qty_per_100_person: detail?.ras_qty_per_100_person || 0,
          std_ras_qty_per_100_person : detail?.std_ras_qty_per_100_person || 0,
          estimated_finished_goods: detail?.estimated_finished_goods || 0,
          estimated_rm_required: detail?.estimated_rm_required || 0,
          fg_consumption_per_person: detail?.fg_consumption_per_person || 0,
          planned_rm_qty: detail?.planned_rm_qty || 0,
          estimated_production: detail?.estimated_production || 0,
        };
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[activeIndex] = {
            ...updatedFormData[activeIndex],
            ...newState,
          };
          return updatedFormData;
        });
      } else {
        const response = await axios.get(
          `${baseUrl}/comman/list?table=item_master&condition=id=${itemId}`,
          { headers: { 'Cache-Control': 'no-cache' } }
        );
        const itemMethodId = response.data?.data[0]?.method ?? null;

        const methodItem = methodList.find((item) => item.id == itemMethodId);
        const methodObject = methodItem
          ? { value: methodItem.id, label: methodItem.name }
          : { value: "", label: "" };

        const consumptionApiCall = await axios.get(
          `${baseUrl}/comman/list?table=consumption_master&condition=menu_item_id='${itemId}' AND method='${itemMethodId}'`
        );
        const consumptionResponse = consumptionApiCall.data.data[0] ?? null;
        const newState = {
          ...formData[activeIndex],
          default_method: methodObject,
          rm_to_fg_ratio: consumptionResponse?.rm_to_fg_ratio || 0,
          fg_consumption_per_person:
            consumptionResponse?.fg_consumption_per_person || 0,
          ras_qty_per_100_person:
            consumptionResponse?.ras_raw_mat_per_100_person || 0,
          std_ras_qty_per_100_person:
            consumptionResponse?.ras_raw_mat_per_100_person || 0,
        };
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[activeIndex] = {
            ...updatedFormData[activeIndex],
            ...newState,
          };
          return updatedFormData;
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getMethodList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=method_master&condition=status=1`
    );
    setMethodList(response.data.data);
  };

  const handelFilterChange = async (date, session) => {
    setDate(date);
    setSession(session);
    
    if (date && session) {
      const condition = ` m.session='${session.value}' AND m.date='${date}'`;
      const response = await axios.get(
        `${baseUrl}/meal_master/item_list?condition=${condition}`
      );
      const result = response.data.data;
      const extractedObject = {
        "date": result[0].date,
        "session": result[0].session,
        "item_name": result[0].item_name,
        "eng_name": result[0].eng_name,
        "menu_item_id": result[0].menu_item_id,
        "id": result[0].id
    };
    
      setMealItemArray([extractedObject]);
      setMealList(result);
    }
  };

  const validation = (index, type) => {
    let newValidationErrors = {
      estimated_count: formData[index].estimated_count === "",
      planned_rm_qty: formData[index].planned_rm_qty === "",
    };
    if (type === "save") {
      newValidationErrors.estimated_production =
        formData[index].estimated_production === "";
    }
    
    setValidationErrors((prevValidationErrors) => {
      const updatedValidationErrors = [...prevValidationErrors];
      updatedValidationErrors[index] = { ...newValidationErrors };
      return updatedValidationErrors;
    });
    return Object.values(newValidationErrors).some((error) => error);
  };

  const validationAll = ( type) => {
    const newValidationErrors = transformedData.map((entry, index) => {
      let errors = {
        estimated_count: entry.estimated_count === "",
        planned_rm_qty: entry.planned_rm_qty === "",
      };
  
      if (type === "save") {
        errors.estimated_production = entry.estimated_production === "";
      }
  
      return errors;
    });
  
    setValidationAllErrors(newValidationErrors);
  
    // Return true if any entry has validation errors
    return newValidationErrors.some((errors) =>
      Object.values(errors).some((error) => error)
    );
   
  };
  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;
    // console.log(name);
    // console.log(value);
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index] = { ...updatedFormData[index], [name]: value };
      return updatedFormData;
    });
    setTransformedData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index] = { ...updatedFormData[index], [name]: value };
      return updatedFormData;
    });

    setValidationErrors((prevValidationErrors) => {
      const updatedValidationErrors = [...prevValidationErrors];
      updatedValidationErrors[index] = {
        ...updatedValidationErrors[index],
        [name]: false,
      };
      return updatedValidationErrors;
    });
  };

  const handleCalculate = (e, index) => {
    e.preventDefault();

    //    const hasErrors = validation(index, "calculate");

    // if(!hasErrors){
    const planned_rm_qty = formData[index].planned_rm_qty ?? 0;
    const rm_to_fg_ratio = formData[index].rm_to_fg_ratio;
    const fg_consumption_per_person = formData[index].fg_consumption_per_person;
    const estimated_count = formData[index].estimated_count ?? 0;
    const ras_qty_per_100_person = formData[index].ras_qty_per_100_person;

    const estimated_finished_goods = (
      planned_rm_qty * rm_to_fg_ratio ?? 0
    ).toFixed(2);
    // if(fg_consumption_per_person){
    //     console.log(estimated_finished_goods / fg_consumption_per_person);
    //     console.log(fg_consumption_per_person);
    // }else{
    //     console.log("3323");
    // }
    const estimated_production =
      fg_consumption_per_person != "" && fg_consumption_per_person != 0
        ? (estimated_finished_goods / fg_consumption_per_person).toFixed(2) ?? 0
        : 0;
    // console.log(estimated_production);
    const estimated_rm_required =
      ras_qty_per_100_person != ""
        ? ((estimated_count * ras_qty_per_100_person) / 100).toFixed(2) ?? 0
        : 0;

    const newState = {
      ...formData[activeIndex],
      estimated_finished_goods: estimated_finished_goods,
      estimated_production: estimated_production,
      estimated_rm_required: estimated_rm_required,
    };
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[activeIndex] = {
        ...updatedFormData[activeIndex],
        ...newState,
      };
      return updatedFormData;
    });
    // }
  };

  const handleCalculateAll = async(e, idx) => {
    e.preventDefault();
    const data = await Promise.all(mealList.map(async (item, index) => {
      if(index === idx){
      const planned_rm_qty = transformedData[index].planned_rm_qty ?? 0;
      const rm_to_fg_ratio = transformedData[index].rm_to_fg_ratio;
      const fg_consumption_per_person = transformedData[index].fg_consumption_per_person;
      const estimated_count = transformedData[index].estimated_count ?? 0;
      const ras_qty_per_100_person = transformedData[index].ras_qty_per_100_person;

      const estimated_rm_required = ras_qty_per_100_person != "" ? ((estimated_count * ras_qty_per_100_person) / 100).toFixed(2) ?? 0 : 0;
      const estimated_finished_goods = (planned_rm_qty * rm_to_fg_ratio ?? 0).toFixed(2);
      const estimated_production = fg_consumption_per_person != "" && fg_consumption_per_person != 0 ? (estimated_finished_goods / fg_consumption_per_person).toFixed(2) ?? 0 : 0;

      const newState = {
        ...transformedData[index],
        estimated_finished_goods: estimated_finished_goods,
        estimated_production: estimated_production,
        estimated_rm_required: estimated_rm_required,
      };
      return newState;
    }else{
      const newState = {
        ...transformedData[index]
      };
      return newState;
    }
    }));

    setTransformedData(data)
  
  }

  const handleSave = async (e, index) => {
    e.preventDefault();

    const hasErrors = validation(index, "save");
    if (!hasErrors) {
      const menu_item_id =
        mealList.find((item, i) => i == index)?.menu_item_id ?? 0;
      const combineData = {
        formData: formData[index],
        date: date,
        session: session,
        menu_item_id: menu_item_id,
      };
      // console.log(combineData);
      try {
        // setPopupOpen(true);
        let url;
        if (detail.length > 0) {
          url = `raw_material/edit/${detail[0].id}`;
        } else {
          url = "raw_material/add";
        }
        await axios.post(`${baseUrl}/${url}`, combineData);
        toast.success("Data successfully saved!");
        // setPopupOpen(false);
        // navigate("/raw_material/list");
      } catch (error) {
        toast.error("Failed to submit the form.");
        // setPopupOpen(false);
        console.log(error);
      }
    }
  };

  const handleSaveAll = async (e) => {
    e.preventDefault();
    const hasErrors = validationAll("save");
    if (!hasErrors) {
      let idx = [];
      const data = await Promise.all(transformedData.map(async(item, index) => {
        // console.log(index);
      const menu_item_id =
        mealList.find((item, i) => i == index)?.menu_item_id ?? 0;
      const combineData = {
        formData: item,
        date: date,
        session: session,
        menu_item_id: menu_item_id,
      };
      try {
      let url;
        if (item.db_id > 0) {
          url = `raw_material/edit/${item.db_id}`;
        } else {
          url = "raw_material/add";
        }
         await axios.post(`${baseUrl}/${url}`, combineData);
         idx.push(index + 1);
        //  console.log(idx);
      } catch (error) {
        toast.error("Failed to submit the form.");
        // setPopupOpen(false);
        console.log(error);
      }
     }));
     if(idx.length == transformedData.length){
      toast.success("Data successfully saved!");
          window.location.reload();
     }else{
      toast.error("Failed to submit the form.");
     }
    //  console.log(transformedData.length);
    //  
    }
  }

  const handleRefresh = async (e) => {
    e.preventDefault();
    const itemId =
      mealList.find((item, index) => index === activeIndex).menu_item_id ??
      null;
    getDetailDispaly("", itemId);
  };

  const handleRefreshAll = async (e, idx) => {
    e.preventDefault()
    const data = await Promise.all(mealList.map(async (item, index) => {
      if(idx == index){

      
      const itemId = mealList[index].menu_item_id;
      const response = await axios.get(
        `${baseUrl}/comman/list?table=item_master&condition=id=${itemId}`
      );
      const itemMethodId = response.data?.data[0]?.method ?? null;
      const consumptionApiCall = await axios.get(
        `${baseUrl}/comman/list?table=consumption_master&condition=menu_item_id='${itemId}' AND method='${itemMethodId}'`
      );
      const consumptionResponse = consumptionApiCall.data.data[0] ?? null;
      const newState = {
        ...transformedData[index],
        rm_to_fg_ratio: consumptionResponse?.rm_to_fg_ratio || 0,
        fg_consumption_per_person:
          consumptionResponse?.fg_consumption_per_person || 0,
        ras_qty_per_100_person:
          consumptionResponse?.ras_raw_mat_per_100_person || 0,
        std_ras_qty_per_100_person:
          consumptionResponse?.ras_raw_mat_per_100_person || 0,
      };
      return newState;
    }else{
      return {
        ...transformedData[index]
      }
    }
    }));
    
    setTransformedData(data);
  
  }

  useEffect(() => {
    const fetchData = async () => {
      if (mealList.length > 0) {
        
        const data = [];

        for (const [index, item] of mealList.entries()) {
          const itemId = mealList[index].menu_item_id;
          const condition = `menu_item_id='${itemId}' AND date = '${date}' AND session='${session.value}'`;
          const detailResponse = await axios.get(`${baseUrl}/comman/list?table=raw_material&condition=${condition}`);
          const apiDetail = detailResponse.data.data[0];
          // console.log(condition);
          const frezzCondition = `menu_item_id='${itemId}' AND date = '${date}' AND session='${session.value}'`;
          const checkFrezz = await axios.get(`${baseUrl}/comman/list?table=production_receipe_planning&condition=${frezzCondition}`);
          const itemDisable = checkFrezz.data.data.length > 0 ? true : false;
          const result = await getTableDetailDispaly(apiDetail, itemId, item, index, mealList, itemDisable);
          data.push(result);
        };

        setTransformedData(data);
      }
    };

    fetchData();
  }, [mealList]);

  const getTableDetailDispaly = async(apiDetail, itemId, item, index, mealList, itemDisable) => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=item_master&condition=id=${itemId}`
    );
    const itemMethodId = response.data?.data[0]?.method ?? null;
    const consumptionApiCall = await axios.get(
      `${baseUrl}/comman/list?table=consumption_master&condition=menu_item_id='${itemId}' AND method='${itemMethodId}'`
    );
    const consumptionResponse = consumptionApiCall.data.data[0] ?? null;
    if(apiDetail){
 
      const newState = {
        item_name: mealList[index].item_name,
        eng_name: mealList[index].eng_name,
        menu_item_id: mealList[index].menu_item_id,
        estimated_count: item.estimated_count ? item.estimated_count : apiDetail?.estimated_count || 0,
        std_ras_qty_per_100_person: consumptionResponse?.ras_raw_mat_per_100_person ?? 0,        
        ras_qty_per_100_person: apiDetail.ras_qty_per_100_person ? apiDetail.ras_qty_per_100_person : consumptionResponse?.ras_raw_mat_per_100_person ?? 0,
        estimated_rm_required: apiDetail?.estimated_rm_required || 0,
        planned_rm_qty: item.planned_rm_qty ? item.planned_rm_qty : apiDetail?.planned_rm_qty || 0,
        rm_to_fg_ratio: consumptionResponse?.rm_to_fg_ratio ?? 0,
        estimated_finished_goods: apiDetail?.estimated_finished_goods || 0,
        fg_consumption_per_person: consumptionResponse?.fg_consumption_per_person ?? 0,
        estimated_production: apiDetail?.estimated_production || 0,
        db_id: apiDetail?.id,
        itemDisable: role == 1 ? false : itemDisable
      };
      return newState;
    }else{
      

      const ras_qty_per_100_person = item.ras_qty_per_100_person ? item.ras_qty_per_100_person : consumptionResponse?.ras_raw_mat_per_100_person ?? 0;
      const ob = {
        item_name: mealList[index].item_name,
        eng_name: mealList[index].eng_name,
        menu_item_id: mealList[index].menu_item_id,
        estimated_count: item.estimated_count ?? 0,
        std_ras_qty_per_100_person: consumptionResponse?.ras_raw_mat_per_100_person ?? 0,
        ras_qty_per_100_person: ras_qty_per_100_person,
        estimated_rm_required: "",
        planned_rm_qty: item.planned_rm_qty ?? 0,
        rm_to_fg_ratio: consumptionResponse?.rm_to_fg_ratio ?? 0,
        estimated_finished_goods: "",
        fg_consumption_per_person: consumptionResponse?.fg_consumption_per_person ?? 0,
        estimated_production: "",
        itemDisable: role == 1 ? false : itemDisable
      };
      return ob;
    }
  }

  const handelAllEstimateCount = (e) => {
    e.preventDefault();
    const estimated_count = e.target.value;
    if(estimated_count){
      const updatedArray = transformedData.map(obj => {
        if (obj.itemDisable === false) {
          // Set the count value for items where itemDisable is false
          const planned_rm_qty = obj.planned_rm_qty ?? 0;
          const rm_to_fg_ratio = obj.rm_to_fg_ratio;
          const fg_consumption_per_person = obj.fg_consumption_per_person;
          const ras_qty_per_100_person = obj.ras_qty_per_100_person;
  
          const estimated_rm_required = ras_qty_per_100_person != "" ? ((estimated_count * ras_qty_per_100_person) / 100).toFixed(2) ?? 0 : 0;
          const estimated_finished_goods = (planned_rm_qty * rm_to_fg_ratio ?? 0).toFixed(2);
          const estimated_production = fg_consumption_per_person != "" && fg_consumption_per_person != 0 ? (estimated_finished_goods / fg_consumption_per_person).toFixed(2) ?? 0 : 0;
  
  
          return {
            ...obj,
            estimated_count: estimated_count,
            estimated_finished_goods: estimated_finished_goods,
            estimated_production: estimated_production,
            estimated_rm_required: estimated_rm_required,
          };
        } else {
          // Keep the original object if itemDisable is true
          return obj;
        }
      });
      
      setTransformedData(updatedArray);
    }
  }

  const editMeal = async () => {
    navigate(`/meal_master/edit/${btoa(mealItemArray[0].id)}`, { state: mealItemArray });
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
          Production Estimation
        </h1>
        {/* <div>
                    <Link to="/menu/add">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Add Menuitem</button>
                    </Link>
                </div> */}
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
      <DateSessionFilter handleFilterChange={handelFilterChange} />
      {/* <div className="flex justify-end mb-4">
      <input type="number" 
                  step="0.01" 
                  id="estimated_count" 
                  name="estimated_count" 
                  placeholder="Estimated Count"
                  onBlur={(e) => handelAllEstimateCount(e)}
                  className={`px-2 py-1 border rounded-md bg-orange-50`} 
                />
          <a href="/reports/summary" target="_blank">
            <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-2">Check Ras</button>
          </a>
          <a href="/meal_master/list" target="_blank">
            <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-2">Add Meal</button>
          </a>
        <button
                      className="px-4 py-2 bg-green-500 text-white rounded-md mr-2"
                      onClick={(e) => {
                        handleSaveAll(e);
                      }}
                    >
                      Save
                    </button>
                  </div>  */}
                  <div className="flex flex-col md:flex-row justify-between items-center mb-4">
  {/* Input aligned to the left side of the screen */}
  <div className="w-full md:w-auto">
    <input
      type="number"
      step="0.01"
      id="estimated_count"
      name="estimated_count"
      placeholder="Estimated Count"
      onBlur={(e) => handelAllEstimateCount(e)}
      onWheel={(e) => e.target.blur()}
      className="w-full md:w-auto px-2 py-1 border rounded-md bg-orange-50"
    />
  </div>

  {/* Buttons aligned to the right, wrapping below input on smaller screens */}
  <div className="mt-4 md:mt-0 flex flex-col md:flex-row md:space-x-2 w-full md:w-auto justify-end">
    <a href="/reports/summary" target="_blank" className="w-full md:w-auto">
      <button className="w-full md:w-auto px-4 py-2 bg-blue-500 text-white rounded-md mb-2 md:mb-0">
        Check Ras
      </button>
    </a>
    <a onClick={() => editMeal()} target="_blank" className="w-full md:w-auto">
      <button className="w-full md:w-auto px-4 py-2 bg-blue-500 text-white rounded-md mb-2 md:mb-0">
        Add Meal
      </button>
    </a>
    <button
      className="w-full md:w-auto px-4 py-2 bg-green-500 text-white rounded-md"
      onClick={(e) => handleSaveAll(e)}
    >
      Save
    </button>
  </div>
</div>

        <table className="border w-full">
          <thead>
              <tr>
                <th className="p-2">Meal Item Name</th>
                <th className="p-2">Estimated Count (Persons)</th>
                <th className="p-2">Std Ras Qty/100 (Persons)</th>
                <th className="p-2">Planned Ras Qty/100 (Persons)</th>
                <th className="p-2">Estimated RM Qty</th>
                <th className="p-2">Planned RM Qty</th>
                <th className="p-2">Estimated Production (Persons)</th>
                <th className="p-2">FG Cmp./ Persons</th>
                <th className="p-2">Estimated Finsihed Goods</th>
                <th className="p-2">RM to FG Ratio</th>
                <th></th>
              </tr>
          </thead>
          <tbody>
          {transformedData.map((item, index) => (
            <tr key={index}>
              <td className="p-2 text-center">{`${item.item_name} (${item.eng_name})`}</td>
              <td className="p-2 text-center">              
                <input type="number" 
                  step="0.01" 
                  id="estimated_count" 
                  name="estimated_count" 
                  value={item?.estimated_count} 
                  onChange={handleInputChange(index)}
                  onBlur={(e) => handleCalculateAll(e, index)}
                  onWheel={(e) => e.target.blur()}
                  disabled={item.itemDisable}
                  className={`${validationAllErrors?.[index]?.estimated_count ? "border-red-500" : "border-gray-300"} 
                  px-2 py-1 border rounded-md w-full bg-orange-50`} 
                />
              </td>
              <td className="p-2 text-center">
                <input type="number" 
                  step="0.01" 
                  id="std_ras_qty_per_100_person" 
                  name="std_ras_qty_per_100_person" 
                  value={item.std_ras_qty_per_100_person} 
                  disabled
                  className="px-2 py-1 border rounded-md w-full"
                />
              </td>
              <td className="p-2 text-center">
                <input
                  type="number"
                  step="0.01"
                  id="ras_qty_per_100_person"
                  name="ras_qty_per_100_person"
                  value={item?.ras_qty_per_100_person}
                  onChange={handleInputChange(index)}
                  onBlur={(e) => handleCalculateAll(e, index)}
                  onWheel={(e) => e.target.blur()}
                  disabled={item.itemDisable}
                  className="px-2 py-1 border rounded-md w-full bg-orange-50"
                />
              </td>
              <td className="p-2 text-center">
                <input
                  type="number"
                  step="0.01"
                  id="estimated_rm_required"
                  disabled
                  value={item?.estimated_rm_required}
                  className="px-2 py-1 border rounded-md w-full"
                />
              </td>
              <td className="p-2 text-center">
                <input
                  type="number"
                  step="0.01"
                  id="planned_rm_qty"
                  name="planned_rm_qty"
                  value={item?.planned_rm_qty}
                  onChange={handleInputChange(index)}
                  onBlur={(e) => handleCalculateAll(e, index)}
                  onWheel={(e) => e.target.blur()}
                  disabled={item.itemDisable}
                  className={`${validationAllErrors?.[index]?.planned_rm_qty
                      ? "border-red-500"
                      : "border-gray-300"
                    } px-2 py-1 border rounded-md w-full bg-lime-300`}
                />
              </td>
              <td className="p-2 text-center">
                <input
                  type="number"
                  step="0.01"
                  id="estimated_production"
                  disabled
                  name="estimated_production"
                  value={item?.estimated_production}
                  className={`${validationAllErrors?.[index]?.estimated_production
                      ? "border-red-500"
                      : "border-gray-300"
                    } px-2 py-1 border rounded-md w-full`}
                />
              </td>
              <td className="p-2 text-center">
                <input
                  type="number"
                  step="0.01"
                  name="fg_consumption_per_person"
                  disabled
                  id="fg_consumption_per_person"
                  value={item?.fg_consumption_per_person}
                  className="px-2 py-1 border rounded-md w-28"
                />
              </td>
              <td className="p-2 text-center">
                <input
                  type="number"
                  step="0.01"
                  id="estimated_finished_goods"
                  disabled
                  value={item?.estimated_finished_goods}
                  className="px-2 py-1 border rounded-md w-full"
                />
              </td>
              <td className="p-2 text-center">
                <input
                  type="number"
                  step="0.01"
                  id="rm_to_fg_ratio"
                  name="rm_to_fg_ratio"
                  disabled
                  value={item?.rm_to_fg_ratio}
                  className="px-2 py-1 border rounded-md w-20"
                />
              </td>
              {/* <td><button
                      className="px-4 py-2 bg-gray-500 text-white rounded-md"
                      onClick={(e) => handleRefreshAll(e, index)}
                    >
                      Refresh
                    </button></td> */}
            </tr>
          ))}
          </tbody>
        </table>
        {/* <div className="flex justify-end mt-4">
                    <button
                      className="px-4 py-2 bg-blue-500 text-white rounded-md mr-2"
                      onClick={(e) => handleCalculateAll(e)}
                    >
                      Calculate
                    </button>
                    <button
                      className="px-4 py-2 bg-green-500 text-white rounded-md mr-2"
                      // onClick={(e) => {
                      //   handleSave(e, index);
                      // }}
                    >
                      Save
                    </button>
                    <button
                      className="px-4 py-2 bg-gray-500 text-white rounded-md"
                      onClick={(e) => handleRefreshAll(e)}
                    >
                      Refresh
                    </button>
                  </div> */}
      </div>
      {/* <div className="relative  shadow-md sm:rounded-lg">
        <div>
          {mealList.map((item, index) => (
            <div key={index} className="border-b mt-4">
              <div
                className="flex justify-between items-center p-4 cursor-pointer accordion-title bg-orange-400 border rounded"
                onClick={() => handleClick(index)}
              >
                <span>{`${item.item_name} (${item.eng_name})`}</span>
                {index === activeIndex ? (
                  <ChevronUpIcon className="w-6 h-6" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6" />
                )}
              </div>
              {index === activeIndex && (
                <div className="space-y-2 p-4 bg-white">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-8">
                    <div className="flex flex-col sm:flex-row items-center"></div>
                    <div className="flex flex-col sm:flex-row items-center">
                      <label
                        htmlFor="field2"
                        className="mr-2 w-full sm:w-auto md:w-52"
                      >
                        Default Method
                      </label>
                      <Select
                        classNamePrefix="select"
                        value={formData[index]?.default_method}
                        name="default_method"
                        // onChange={(selectedOption, name) => {
                        //     // handleSelectChange(selectedOption, name);
                        // }}
                        className="ms-0 w-full sm:w-auto md:w-48 "
                        options={methodList.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                      
                        isDisabled={true}
                        filterOption={(option, inputValue) => {
                          return option.data.label
                            .toLowerCase()
                            .includes(inputValue.toLowerCase());
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-8">
                    <div className="flex flex-col items-start sm:flex-row sm:items-center">
                      <label
                        htmlFor="estimated_count"
                        className="mr-2 w-full sm:w-auto md:w-52 lg:w-52"
                      >
                        Estimated Count (Persons)
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        id="estimated_count"
                        name="estimated_count"
                        value={formData?.[index]?.estimated_count}
                        onChange={handleInputChange(index)}
                        className={`${validationErrors?.[index]?.estimated_count
                            ? "border-red-500"
                            : "border-gray-300"
                          } px-4 py-2 border rounded-md w-full sm:w-auto bg-orange-50`}
                      />
                    </div>

                    <div className="flex flex-col items-start sm:flex-row sm:items-center">
                      <label
                        htmlFor="rm_to_fg_ratio"
                        className="mr-2 w-full sm:w-auto md:w-52 lg:w-52"
                      >
                        RM to FG Ratio
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        id="rm_to_fg_ratio"
                        name="rm_to_fg_ratio"
                        readOnly
                        value={formData[index]?.rm_to_fg_ratio}
                        className="px-4 py-2 border rounded-md w-full sm:w-auto"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-8">
                    <div className="flex flex-col items-start sm:flex-row sm:items-center">
                      <label
                        htmlFor="ras_qty_per_100_person"
                        className="mr-2 w-full sm:w-auto md:w-52"
                      >
                        Planned Ras = Qty / 100 Persons (Kgs)
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        id="ras_qty_per_100_person"
                        name="ras_qty_per_100_person"
                        value={formData?.[index]?.ras_qty_per_100_person}
                        onChange={handleInputChange(index)}
                        className="px-4 py-2 border rounded-md w-full sm:w-auto bg-orange-50"
                      />
                    </div>
                    <div className="flex flex-col items-start sm:flex-row sm:items-center">
                      <label
                        htmlFor="estimated_finished_goods"
                        className="mr-2 w-full sm:w-auto md:w-52"
                      >
                        Estimated Finsihed Goods
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        id="estimated_finished_goods"
                        disabled
                        value={formData?.[index]?.estimated_finished_goods}
                        className="px-4 py-2 border rounded-md w-full sm:w-auto"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-8">
                    <div className="flex flex-col items-start sm:flex-row sm:items-center">
                      <label
                        htmlFor="std_ras_qty_per_100_person"
                        className="mr-2 w-full sm:w-auto md:w-52"
                      >
                        Std Ras = Qty / 100 Persons (Kgs)
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        id="std_ras_qty_per_100_person"
                        readOnly
                        value={formData?.[index]?.std_ras_qty_per_100_person}
                        className="px-4 py-2 border rounded-md w-full sm:w-auto"
                      />
                    </div>
                    <div className="flex flex-col items-start sm:flex-row sm:items-center">
                      
                    </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-8">
                    <div className="flex flex-col items-start sm:flex-row sm:items-center">
                      <label
                        htmlFor="estimated_rm_required"
                        className="mr-2 w-full sm:w-auto md:w-52"
                      >
                        Estimated RM Required (Kgs)
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        id="estimated_rm_required"
                        disabled
                        value={formData?.[index]?.estimated_rm_required}
                        className="px-4 py-2 border rounded-md w-full sm:w-auto"
                      />
                    </div>
                    <div className="flex flex-col items-start sm:flex-row sm:items-center">
                      <label
                        htmlFor="fg_consumption_per_person"
                        className="mr-2 w-full sm:w-auto md:w-52"
                      >
                        FG Consumption / Person
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        name="fg_consumption_per_person"
                        disabled
                        id="fg_consumption_per_person"
                        value={formData[index]?.fg_consumption_per_person}
                        className="px-4 py-2 border rounded-md w-full sm:w-auto"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-8">
                    <div className="flex flex-col items-start sm:flex-row sm:items-center">
                      <label
                        htmlFor="planned_rm_qty"
                        className="mr-2 w-full sm:w-auto md:w-52"
                      >
                        Planned RM Qty (Kgs)
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        id="planned_rm_qty"
                        name="planned_rm_qty"
                        value={formData?.[index]?.planned_rm_qty}
                        onChange={handleInputChange(index)}
                        className={`${validationErrors?.[index]?.planned_rm_qty
                            ? "border-red-500"
                            : "border-gray-300"
                          } px-4 py-2 border rounded-md w-full sm:w-auto bg-lime-300`}
                      />
                    </div>
                    <div className="flex flex-col items-start sm:flex-row sm:items-center">
                      <label
                        htmlFor="estimated_production"
                        className="mr-2 w-full sm:w-auto md:w-52"
                      >
                        Estimated Production (persons)
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        id="estimated_production"
                        disabled
                        name="estimated_production"
                        value={formData?.[index]?.estimated_production}
                        className={`${validationErrors?.[index]?.estimated_production
                            ? "border-red-500"
                            : "border-gray-300"
                          } px-4 py-2 border rounded-md w-full sm:w-auto`}
                      />
                    </div>
                  </div>

                  <div className="flex justify-end mt-4">
                    <button
                      className="px-4 py-2 bg-blue-500 text-white rounded-md mr-2"
                      onClick={(e) => handleCalculate(e, index)}
                    >
                      Calculate
                    </button>
                    <button
                      className="px-4 py-2 bg-green-500 text-white rounded-md mr-2"
                      onClick={(e) => {
                        handleSave(e, index);
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="px-4 py-2 bg-gray-500 text-white rounded-md"
                      onClick={(e) => handleRefresh(e)}
                    >
                      Refresh
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
       */}
    </div>
  );
}

export default RawMaterial;
