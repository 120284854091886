// OrderIngredientModel.js
import React, {useState, useEffect} from "react";
import axios from "axios";
import {baseUrl} from "../comman/config";
import toast from "react-hot-toast";

const OrderIngredientModel = ({ showOrderModal, setShowOrderModal, ingredientList, clearItem  }) => {
  const [orderItemList , setOrderItemList] = useState([]);

  useEffect(() => {
    if (showOrderModal) {
      const result = ingredientList.reduce((acc, item) => {
        const { ingredient_id, ingredient, order_qty } = item;

        if (!acc[ingredient_id]) {
            acc[ingredient_id] = { ingredient_id, ingredient, order_qty: 0 };
        }

        // Sum the order_qty
        acc[ingredient_id].order_qty += parseFloat(order_qty);

        // If available_qty exists, keep the first occurrence (if needed)
        if (item.available_qty) {
            acc[ingredient_id].available_qty = item.available_qty;
        }

        return acc;
    }, {});
      setOrderItemList(Object.values(result));
    }
  }, [showOrderModal, ingredientList]);
  const handleClose = () => {
    setShowOrderModal(false);
    clearItem(); // Clear the selected item when closing the modal
  };

  const handleAddOrder = async() => {
    try{
      if(window.confirm(`આ શાકભાજી "ઓર્ડર" થવા જઈ રહી છે. હવે પછી "ઓર્ડર" માં કોઈ સુધારો થઇ શકશે નહી .
કોઈ કારણસર કોઈ સુધારો કરવાનો થાય તો "શાકભાજી પરચેઝ (ખરીદ) ટીમ" ને જાણ કરવી.
શું તમે "ઓર્ડર" કરવા માંગો છો ?`)){
        const response = await axios.post(`${baseUrl}/order/ingredient_place_order`,
          { ingredientsList : ingredientList});
        const order_no = response.data.data;
        toast.success(`Order No: ${order_no} has been successfully generated`);
        handleClose();
      }
    }catch (error) {
      toast.error("Failed to add item. Please try again.");
    }
    
  }

  if (!showOrderModal) return null;

  return (
    <>
      {showOrderModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  
                    
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={handleClose}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                
                <div className="relative p-6 flex-auto">
                  <table className="border w-full">
                    <thead>
                      <tr>
                        <th className="p-2">Ingredient</th>
                        <th className="p-2">Total Order Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderItemList.map((item, index) =>(
                        <tr key={index}>
                          <td className="p-2 text-center">{item.ingredient}</td>
                          <td className="p-2 text-center">{isNaN(item.order_qty) ? 0 : item.order_qty}</td>
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => handleAddOrder()}
                  >
                    Placed Order
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default OrderIngredientModel;
